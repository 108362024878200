<template>
  <div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-10">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><router-link :to="{path:'/admin/'}">Dashboard</router-link></li>
                    <li class="breadcrumb-item"><a href="#">Master</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Env Variabel Configuration</li>
                </ol>
            </nav>
        </div>
        <div class="col-lg-2">
            <button class="btn btn-success btn-sm" style="float: right;" @click="showModalCreate()">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-plus-circle"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="16"></line><line x1="8" y1="12" x2="16" y2="12"></line></svg>
            </button>            
        </div>
        <div class="col-md-12">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-lg-12">
                            <table class="table table-striped table-sm table-bordered table-hover display nowrap" id="example" style="width:100%">
                                <thead>
                                    <tr>
                                        <th style="text-align:center !important">Entity</th>
                                        <th style="text-align:center !important">Application</th>
                                        <th style="text-align:center !important">Variabel ID</th>
                                        <th style="text-align:center !important">Group</th>
                                        <th style="text-align:center !important">User</th>
                                        <th style="text-align:center !important">Value</th>
                                        <th style="text-align:center !important">Note</th>
                                        <th style="text-align:center !important">Status</th>
                                        <th style="text-align:center !important">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="env_var in env_vars" :key="env_var.id">
                                        <td>{{env_var.entity_id}}</td>
                                        <td>{{env_var.appl_id}}</td>
                                        <td>{{env_var.var_id}}</td>
                                        <td>{{env_var.group_id}}</td>
                                        <td>{{env_var.user_id2}}</td>
                                        <td>{{env_var.var_value}}</td>
                                        <td>{{env_var.note1}}</td>
                                        <td>
                                            <label class="switch">
                                                <input type="checkbox" v-on:change="deleteEnvVar(env_var)" :checked="env_var.active_flag === 'N' ? false : true">
                                                <span class="slider round"></span>
                                            </label>
                                        </td>
                                        <td>
                                            <button class="btn btn-sm btn-warning" @click="EditModal(env_var)">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit-2"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="createEnvVarModal" tabindex="-1" aria-labelledby="createEnvVarModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="createEnvVarModalLabel">Tambah Environment Variabel Config</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body py-lg-10 px-lg-10">
                    <div class="row g-3 align-items-center">
                        <div class="col-md-3">
                            <label for="entity" class="col-form-label">Entity</label>
                        </div>
                        <div class="col-auto">
                            <multiselect 
                                v-model="entity" 
                                :custom-label="serviceEntity" 
                                :options="entities" 
                                placeholder="Select One" 
                                label="description" 
                                track-by="description" 
                                :allow-empty="false" 
                                :showLabels="false" 
                                name="entity_id" 
                                autofocuc="yes"
                                class="form-control"
                                @input="setEntity()"
                            ></multiselect>
                        </div>
                    </div>
                    <div class="row g-3 align-items-center mt-1">
                        <div class="col-md-3">
                            <label for="entity" class="col-form-label">Application</label>
                        </div>
                        <div class="col-auto">
                            <multiselect 
                                v-model="application" 
                                :custom-label="serviceApplication" 
                                :options="applications" 
                                placeholder="Select One" 
                                label="appl_id" 
                                track-by="appl_id" 
                                :allow-empty="false" 
                                :showLabels="false" 
                                name="appl_id" 
                                autofocuc="yes"
                                class="form-control"
                                @input="setVariabel()"
                            ></multiselect>
                        </div>
                    </div>
                    <div class="row g-3 align-items-center mt-1">
                        <div class="col-md-3">
                            <label for="entity" class="col-form-label">Variabel ID</label>
                        </div>
                        <div class="col-auto">
                            <multiselect 
                                v-model="env_var_select" 
                                :custom-label="serviceVariabelID" 
                                :options="env_var_selects" 
                                placeholder="Select One" 
                                label="appl_id" 
                                track-by="appl_id" 
                                :allow-empty="false" 
                                :showLabels="false" 
                                name="appl_id" 
                                autofocuc="yes"
                                class="form-control"
                            ></multiselect>
                        </div>
                    </div>
                    <div class="row g-3 align-items-center mt-1">
                        <div class="col-md-3">
                            <label for="entity" class="col-form-label">Group ID</label>
                        </div>
                        <div class="col-auto">
                            <multiselect 
                                v-model="group" 
                                :custom-label="serviceGroupID" 
                                :options="groups" 
                                placeholder="Select One" 
                                label="group_id" 
                                track-by="group_id" 
                                :allow-empty="false" 
                                :showLabels="false" 
                                name="group_id" 
                                autofocuc="yes"
                                class="form-control"
                            ></multiselect>
                        </div>
                    </div>
                    <div class="row g-3 align-items-center mt-1">
                        <div class="col-md-3">
                            <label for="entity" class="col-form-label">User ID</label>
                        </div>
                        <div class="col-auto">
                            <multiselect 
                                v-model="user" 
                                :custom-label="serviceUserID" 
                                :options="users" 
                                placeholder="Select One" 
                                label="user_id2" 
                                track-by="user_id2" 
                                :allow-empty="false" 
                                :showLabels="false" 
                                name="user_id2" 
                                autofocuc="yes"
                                class="form-control"
                            ></multiselect>
                        </div>
                    </div>
                    <div class="row g-3 align-items-center mt-1">
                        <div class="col-md-3">
                            <label for="type" class="col-form-label">Type Var</label>
                        </div>
                        <div class="col-auto">
                            <select class="form-select" aria-label="Select" v-model="type">
                                <option value="S">String</option>
                                <option value="N">Number</option>
                                <option value="D">Date</option>
                            </select>
                        </div>
                    </div>
                    <div class="row g-3 align-items-center mt-1">
                        <div class="col-md-3">
                            <label for="var_value" class="col-form-label">Var Value</label>
                        </div>
                        <div class="col-auto">
                            <input type="text" id="var_value" class="form-control" aria-describedby="var_valueHelpInline" v-model="var_value">
                        </div>
                    </div>
                    <div class="row g-3 align-items-center mt-1">
                        <div class="col-md-3">
                            <label for="note1" class="col-form-label">Note</label>
                        </div>
                        <div class="col-auto">
                            <input type="text" id="note1" class="form-control" aria-describedby="note1HelpInline" v-model="note1">
                        </div>
                    </div>
                    <div class="row g-3 align-items-center mt-1">
                        <div class="col-md-3">
                            <label for="status" class="col-form-label">Status</label>
                        </div>
                        <div class="col-auto">
                            <select class="form-select" aria-label="Select" v-model="status">
                                <option value="Y">Aktif</option>
                                <option value="N">Tidak Aktif</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="button" class="btn btn-success" @click="addEnvVariable()">Save</button>
                </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="EditEnvVariabelModal" tabindex="-1" aria-labelledby="EditEnvVariabelModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="EditEnvVariabelModalLabel">Edit Environment Variabel Config</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body py-lg-10 px-lg-10">
                    <div class="row g-3 align-items-center">
                        <div class="col-md-3">
                            <label for="entity" class="col-form-label">Entity</label>
                        </div>
                        <div class="col-auto">
                            <multiselect 
                                v-model="editEntity" 
                                :custom-label="serviceEntity" 
                                :options="entities" 
                                placeholder="Select One" 
                                label="description" 
                                track-by="description" 
                                :allow-empty="false" 
                                :showLabels="false" 
                                name="entity_id" 
                                autofocuc="yes"
                                class="form-control"
                                disabled
                            ></multiselect>
                        </div>
                    </div>
                    <div class="row g-3 align-items-center mt-1">
                        <div class="col-md-3">
                            <label for="entity" class="col-form-label">Application</label>
                        </div>
                        <div class="col-auto">
                            <multiselect 
                                v-model="editApplication" 
                                :custom-label="serviceApplication" 
                                :options="applications" 
                                placeholder="Select One" 
                                label="appl_id" 
                                track-by="appl_id" 
                                :allow-empty="false" 
                                :showLabels="false" 
                                name="appl_id" 
                                autofocuc="yes"
                                class="form-control"
                                disabled
                            ></multiselect>
                        </div>
                    </div>
                    <div class="row g-3 align-items-center mt-1">
                        <div class="col-md-3">
                            <label for="entity" class="col-form-label">Variabel ID</label>
                        </div>
                        <div class="col-auto">
                            <multiselect 
                                v-model="edit_env_var_select" 
                                :custom-label="serviceVariabelID" 
                                :options="env_var_selects" 
                                placeholder="Select One" 
                                label="appl_id" 
                                track-by="appl_id" 
                                :allow-empty="false" 
                                :showLabels="false" 
                                name="appl_id" 
                                autofocuc="yes"
                                class="form-control"
                                disabled
                            ></multiselect>
                        </div>
                    </div>
                    <div class="row g-3 align-items-center mt-1">
                        <div class="col-md-3">
                            <label for="entity" class="col-form-label">Group ID</label>
                        </div>
                        <div class="col-auto">
                            <multiselect 
                                v-model="editGroup" 
                                :custom-label="serviceGroupID" 
                                :options="groups" 
                                placeholder="Select One" 
                                label="group_id" 
                                track-by="group_id" 
                                :allow-empty="false" 
                                :showLabels="false" 
                                name="group_id" 
                                autofocuc="yes"
                                class="form-control"
                                disabled
                            ></multiselect>
                        </div>
                    </div>
                    <div class="row g-3 align-items-center mt-1">
                        <div class="col-md-3">
                            <label for="entity" class="col-form-label">User ID</label>
                        </div>
                        <div class="col-auto">
                            <multiselect 
                                v-model="editUser" 
                                :custom-label="serviceUserID" 
                                :options="users" 
                                placeholder="Select One" 
                                label="user_id2" 
                                track-by="user_id2" 
                                :allow-empty="false" 
                                :showLabels="false" 
                                name="user_id2" 
                                autofocuc="yes"
                                class="form-control"
                                disabled
                            ></multiselect>
                        </div>
                    </div>
                    <div class="row g-3 align-items-center mt-1">
                        <div class="col-md-3">
                            <label for="type" class="col-form-label">Type Var</label>
                        </div>
                        <div class="col-auto">
                            <select class="form-select" aria-label="Select" v-model="editType">
                                <option value="S">String</option>
                                <option value="N">Number</option>
                                <option value="D">Date</option>
                            </select>
                        </div>
                    </div>
                    <div class="row g-3 align-items-center mt-1">
                        <div class="col-md-3">
                            <label for="var_value" class="col-form-label">Var Value</label>
                        </div>
                        <div class="col-auto">
                            <input type="text" id="var_value" class="form-control" aria-describedby="var_valueHelpInline" v-model="editVarValue">
                        </div>
                    </div>
                    <div class="row g-3 align-items-center mt-1">
                        <div class="col-md-3">
                            <label for="note1" class="col-form-label">Note</label>
                        </div>
                        <div class="col-auto">
                            <input type="text" id="note1" class="form-control" aria-describedby="note1HelpInline" v-model="editNote1">
                        </div>
                    </div>
                    <div class="row g-3 align-items-center mt-1">
                        <div class="col-md-3">
                            <label for="status" class="col-form-label">Status</label>
                        </div>
                        <div class="col-auto">
                            <select class="form-select" aria-label="Select" v-model="editStatus">
                                <option value="Y">Aktif</option>
                                <option value="N">Tidak Aktif</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="button" class="btn btn-success" @click="updateEnvVar()">Save</button>
                </div>
                </div>
            </div>
        </div>
        <snackbar ref="snackbar" baseSize="100px" :holdTime="5000" position="top-center"></snackbar>

      </div>
    </div>
  </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<script>
//Bootstrap and jQuery libraries
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery'; 
import Snackbar from "vuejs-snackbar";
import swal from 'sweetalert';
import Multiselect from 'vue-multiselect'


export default {
    mounted() {
        this.getEntity()
        this.getApplication()
        this.getVariabel()
    },
    data(){
        return {
            env_vars: [],
            env_var: {},
            fullPage: true, 
            loader : null,
            variabelId: '',
            description: '',
            status: null,
            type: null,
            entities: [],
            entity: {},
            applications: [],
            application: {},
            route_path: '',
            note1: '',
            env_var_selects: [],
            env_var_select: {},
            var_value: '',
            groups: [],
            group: {},
            users: [],
            user:{},
            editEntity: {},
            editApplication: {},
            edit_env_var_select: {},
            editGroup: {},
            editUser: {},
            editType: '',
            editVarValue: '',
            editNote1: '',
            editStatus: '',
            dataEnvVar: null,
            dataTableVar: null,
            checked: null
        }
    },
    components: {
		Snackbar, Multiselect
	},
    methods:{
        loaderLoad(){
            this.loader = this.$loading.show({
                // Optional parameters
                container: this.fullPage ? null : this.$refs.formContainer,
                color: '#0a58ca',
                loader: 'dots',
                width: 64,
                height: 64,
                backgroundColor: '#222223',
                opacity: 0.5,
                zIndex: 999,
            });
        },
        clear(){
            this.entity = ''
            this.application = ''
            this.env_var_select = ''
            this.group = ''
            this.user = ''
            this.type = ''
            this.var_value = ''
            this.note1 = ''
            this.status = ''
        },
        serviceEntity (option) {
            return `${option.entity_id} - ${option.description}`
        },
        getVariabel(){
            axios.get(`${process.env.VUE_APP_URL}/api/master/env_var?active_flag=Y`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.env_var_selects = res.data
            });
        },
        setVariabel(){
            axios.get(`${process.env.VUE_APP_URL}/api/master/env_var?appl_id=${this.application.appl_id}&active_flag=Y`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.env_var_selects = res.data
                this.getGroupUser()
                this.getGroup()
            });
        },
        getGroupUser(){
            axios.get(`${process.env.VUE_APP_URL}/api/group?entity_id=${this.entity.entity_id}&appl_id=${this.application.appl_id}&active_flag=Y`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                // this.loader.hide();
                this.users = res.data
            });
        },
        getGroup(){
            axios.get(`${process.env.VUE_APP_URL}/api/group/select?entity_id=${this.entity.entity_id}&appl_id=${this.application.appl_id}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.groups = res.data
            });
        },
        serviceGroupID({group_id}){
            return group_id;
        },
        serviceUserID({user_id2}){
            return user_id2;
        },
        setEntity(){
            axios.get(`${process.env.VUE_APP_URL}/api/master/aplikasi?entity_id=${this.entity.entity_id}&status=Y`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.applications = res.data
            });
        },
        getEntity() {    
            axios.get(`${process.env.VUE_APP_URL}/api/master/entity?status=1`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.entities = res.data
            });
        },
        serviceApplication({appl_id}){
			return appl_id
		},
        serviceVariabelID({var_id}){
			return var_id
		},
        getApplication() {    
            axios.get(`${process.env.VUE_APP_URL}/api/master/aplikasi?status=Y`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.applications = res.data
            });
        },
        getEnvVariabel() {    
            this.loaderLoad();

            axios.get(`${process.env.VUE_APP_URL}/api/master/env_conf`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.loader.hide();

                this.env_vars = res.data
                this.$nextTick(() => {
                    this.dataTableVar =     $("#example").DataTable({
                                                "responsive": true,
                                                "autoWidth": false,
                                                "ordering": false,
                                                "info": true,
                                                "pageLength": 10,
                                                "bDestroy": true,
                                                "recordsFiltered":10,
                                                "destroy": true,
                                                "cache" : true,
                                            });
                });
            });
        },
        showModalCreate(){
            $("#createEnvVarModal").modal('show');
        },
        addEnvVariable(){
            $("#createEnvVarModal").modal('hide');
            this.loaderLoad();

            axios.post(`${process.env.VUE_APP_URL}/api/master/env_conf`, {
                'entity_id' : this.entity.entity_id,
                'appl_id': this.application.appl_id,
                'group_id': this.group == undefined ? " " : this.group.group_id,
                'user_id2': this.user == undefined ? " " : this.user.user_id2,
                'var_id': this.env_var_select.var_id,
                'var_value' : this.var_value,
                'var_type' : this.type,
                'note1' : this.note1,
                'status' : this.status
            }, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            }).then(res => {
                this.loader.hide();
                $('#example').DataTable().destroy();
                this.getEnvVariabel();
                this.clear()
                swal("Berhasil tambah env conf", {icon: "success", button: false})
            }).catch(err => {
                this.loader.hide();
                swal(err.response.data.message, {icon: "error", button: false})
                console.log(err.response);
            })
        },
        EditModal(data){
            this.editEntity = data
            this.editApplication = data
            this.edit_env_var_select = data
            this.editGroup = data
            this.editUser = data
            this.editType = data.var_type
            this.editVarValue = data.var_value
            this.editNote1 = data.note1
            this.editStatus = data.active_flag
            this.dataEnvVar = data

            $("#EditEnvVariabelModal").modal('show');
        },
        updateEnvVar(){
            $("#EditEnvVariabelModal").modal('hide');
            this.loaderLoad();

            axios.put(`${process.env.VUE_APP_URL}/api/master/env_conf/update`, {
                'entity_id' : this.editEntity.entity_id,
                'appl_id': this.editApplication.appl_id,
                'group_id': this.editGroup == undefined ? " " : this.editGroup.group_id,
                'user_id2': this.editUser == undefined ? " " : this.editUser.user_id2,
                'var_id': this.edit_env_var_select.var_id,
                'var_value' : this.editVarValue,
                'var_type' : this.editType,
                'note1' : this.editNote1,
                'status' : this.editStatus
            }, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            }).then(res => {
                this.loader.hide();
                $('#example').DataTable().destroy();
                this.getEnvVariabel();
                swal("Berhasil edit env var", {icon: "success", button: false})
            }).catch(err => {
                this.loader.hide();
                swal(err.response.data.message, {icon: "error", button: false})
                console.log(err.response);
            })
        },
        deleteEnvVar(menu){
            if (menu.active_flag === 'Y') {
                this.updateStatus(menu, 'N')
                this.checked = false
            } else{
                this.updateStatus(menu, 'Y')
                this.checked = true
            }
        },
        async updateStatus(menu, status){
            await axios.put(`${process.env.VUE_APP_URL}/api/master/env_conf/updateEnvConfStat`, {
                'entity_id': menu.entity_id,
                'appl_id' : menu.appl_id,
                'var_id' : menu.var_id,
                'group_id' : menu.group_id,
                'user_id2' : menu.user_id2,
                'status': status
            }, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            }).then(res => {
                this.getEnvVariabel();
                $('#example').DataTable().destroy();
                this.dataTableVar.draw()
                swal("Berhasil update status", {icon: "success", button: false})
            }).catch(err => {
                swal(err.response.data.message, {icon: "error", button: false})
            })
        }
    },
    created() {
        this.getEnvVariabel()
    }
}
</script>
<style>

</style>