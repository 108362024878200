<template>
  <div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-10">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><router-link :to="{path:'/admin/'}">Dashboard</router-link></li>
                    <li class="breadcrumb-item"><a href="#">Master</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Entity</li>
                </ol>
            </nav>
        </div>
        <div class="col-lg-2">
            <button class="btn btn-success btn-sm" style="float: right;" @click="showModalCreate()">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-plus-circle"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="16"></line><line x1="8" y1="12" x2="16" y2="12"></line></svg>
            </button>            
        </div>
        <div class="col-md-12">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-lg-12">
                            <table class="table table-striped table-sm table-bordered table-hover display nowrap" id="example" style="width:100%">
                                <thead>
                                    <tr>
                                        <th style="text-align:center !important">Entity</th>
                                        <th style="text-align:center !important">Name</th>
                                        <th style="text-align:center !important">Status</th>
                                        <th style="text-align:center !important">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="entity in entities" :key="entity.id">
                                        <td>{{entity.entity_id}}</td>
                                        <td>{{entity.description}}</td>
                                        <td>
                                            <!-- {{entity.status}} -->
                                            <label class="switch" v-if="entity.status === '0'">
                                                <input type="checkbox" v-on:change="deleteEntity(entity)">
                                                <span class="slider round"></span>
                                            </label>
                                            <label class="switch" v-else>
                                                <input type="checkbox" v-on:change="deleteEntity(entity)" checked>
                                                <span class="slider round"></span>
                                            </label>
                                        </td>
                                        <td>
                                            <!-- Edit -->
                                            <button class="btn btn-sm btn-warning" @click="EditModal(entity)">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit-2"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg>
                                            </button>
                                            <!-- Delete -->
                                            <!-- <button class="btn btn-sm btn-danger" style="margin-left:5px" @click="deleteEntity(entity)">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg>
                                            </button> -->
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="createEntityModal" tabindex="-1" aria-labelledby="createEntityModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="createEntityModalLabel">Tambah Entity</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body py-lg-10 px-lg-10">
                    <div class="row g-3 align-items-center">
                        <div class="col-md-3">
                            <label for="entity" class="col-form-label">Entity</label>
                        </div>
                        <div class="col-auto">
                            <input type="text" id="entity" class="form-control" aria-describedby="entityHelpInline" v-model="entity">
                        </div>
                    </div>
                    <div class="row g-3 align-items-center mt-1">
                        <div class="col-md-3">
                            <label for="description" class="col-form-label">Description</label>
                        </div>
                        <div class="col-auto">
                            <input type="text" id="description" class="form-control" aria-describedby="descriptionHelpInline" v-model="description">
                        </div>
                    </div>
                    <div class="row g-3 align-items-center mt-1">
                        <div class="col-md-3">
                            <label for="status" class="col-form-label">Status</label>
                        </div>
                        <div class="col-auto">
                            <select class="form-select" aria-label="Select" v-model="status">
                                <option value="1">Aktif</option>
                                <option value="0">Tidak Aktif</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="button" class="btn btn-success" @click="addEntity()">Save</button>
                </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="EditEntityModal" tabindex="-1" aria-labelledby="EditEntityModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="EditEntityModalLabel">Edit Entity</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body py-lg-10 px-lg-10">
                    <div class="row g-3 align-items-center">
                        <div class="col-md-3">
                            <label for="entity" class="col-form-label">Entity</label>
                        </div>
                        <div class="col-auto">
                            <input type="text" class="form-control" aria-describedby="entityHelpInline" v-model="editEntity" disabled>
                        </div>
                    </div>
                    <div class="row g-3 align-items-center mt-1">
                        <div class="col-md-3">
                            <label for="description" class="col-form-label">Description</label>
                        </div>
                        <div class="col-auto">
                            <input type="text" class="form-control" aria-describedby="descriptionHelpInline" v-model="editDescription">
                        </div>
                    </div>
                    <div class="row g-3 align-items-center mt-1">
                        <div class="col-md-3">
                            <label for="status" class="col-form-label">Status</label>
                        </div>
                        <div class="col-auto">
                            <select class="form-select" aria-label="Select" v-model="editStatus">
                                <option value="1">Aktif</option>
                                <option value="0">Tidak Aktif</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="button" class="btn btn-success" @click="updateEntity()">Save</button>
                </div>
                </div>
            </div>
        </div>
        <snackbar ref="snackbar" baseSize="100px" :holdTime="5000" position="top-center"></snackbar>

      </div>
    </div>
  </div>
</template>
<script>
//Bootstrap and jQuery libraries
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery'; 
import Snackbar from "vuejs-snackbar";
import swal from 'sweetalert';

export default {
    mounted() {
    },
    data(){
        return {
            entities: [],
            fullPage: true, 
            loader : null,
            entity: '',
            description: '',
            status: null,
            editEntity: '',
            editDescription: '',
            editStatus: '',
            dataEntity: null
        }
    },
    components: {
		Snackbar
	},
    methods:{
        loaderLoad(){
            this.loader = this.$loading.show({
                // Optional parameters
                container: this.fullPage ? null : this.$refs.formContainer,
                color: '#0a58ca',
                loader: 'dots',
                width: 64,
                height: 64,
                backgroundColor: '#222223',
                opacity: 0.5,
                zIndex: 999,
            });
        },
        clear(){
            this.entity = ''
            this.description = ''
            this.status = null
        },
        getEntity() {    
            this.loaderLoad();

            axios.get(`${process.env.VUE_APP_URL}/api/master/entity`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.loader.hide();

                this.entities = res.data
                this.$nextTick(() => {
                    $("#example").DataTable({
                        "responsive": true,
                        "autoWidth": false,
                        "ordering": false,
                        "info": true,
                        "pageLength": 10,
                        "bDestroy": true,
                        "recordsFiltered":10,
                        "destroy": true,
                    });
                });
            });
        },
        showModalCreate(){
            $("#createEntityModal").modal('show');
        },
        addEntity(){
            $("#createEntityModal").modal('hide');
            this.loaderLoad();

            if (this.entity == '' && this.description == '' && this.status == null) {
                this.loader.hide();
                return this.$refs.snackbar.error("Mohon diisi entity, description dan status");

            } else if(this.entity == ''){
                this.loader.hide();
                return this.$refs.snackbar.error("Mohon diisi entity anda");

            } else if(this.description == ''){
                this.loader.hide();
                return this.$refs.snackbar.error("Mohon diisi description anda");
            } 
            
            else if(this.status == null){
                this.loader.hide();
                return this.$refs.snackbar.error("Mohon diisi status entity");
            } 

            axios.post(`${process.env.VUE_APP_URL}/api/master/entity`, {
                'entity': this.entity,
                'description': this.description,
                'status': this.status
            }, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            }).then(res => {
                this.loader.hide();
                $('#example').DataTable().destroy();
                this.getEntity();
                this.clear()
                swal("Berhasil tambah entity", {icon: "success", button: false})
            }).catch(err => {
                console.log(err.response);
                this.loader.hide();
                swal(err.response.data.message, {icon: "error", button: false})
            })
        },
        EditModal(data){
            this.dataEntity = data
            this.editEntity = data.entity_id
            this.editDescription = data.description
            this.editStatus = data.status
            $("#EditEntityModal").modal('show');
        },
        updateEntity(){
            $("#EditEntityModal").modal('hide');
            this.loaderLoad();

            axios.put(`${process.env.VUE_APP_URL}/api/master/entity/update`, {
                'entity': this.editEntity,
                'description': this.editDescription,
                'status': this.editStatus
            }, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            }).then(res => {
                this.loader.hide();
                $('#example').DataTable().destroy();
                this.getEntity();
                swal("Berhasil edit entity", {icon: "success", button: false})
            }).catch(err => {
                console.log(err.response);
                this.loader.hide();
                swal(err.response.data.message, {icon: "error", button: false})
            })
        },
        deleteEntity(entity){
            if (entity.status === '1') {
                this.updateStatus(entity, 0)
            } else{
                this.updateStatus(entity, 1)
            }
        },
        updateStatus(entity, status){
            axios.put(`${process.env.VUE_APP_URL}/api/master/entity/updateEntityStatus`, {
                'entity': entity.entity_id,
                'status': status
            }, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            }).then(res => {
                $('#example').DataTable().destroy();
                this.getEntity();
                swal("Berhasil update status", {icon: "success", button: false})
            }).catch(err => {
                swal(err.response.data.message, {icon: "error", button: false})
            })
        }
    },
    created() {
        this.getEntity()
    }
}
</script>
<style>

</style>