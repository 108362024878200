<template>
  <div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-10">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><router-link :to="{path:'/admin/'}">Dashboard</router-link></li>
                    <li class="breadcrumb-item"><a href="#">Master</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Menu Item</li>
                </ol>
            </nav>
        </div>
        <div class="col-lg-2">
            <button class="btn btn-success btn-sm" style="float: right;" @click="showModalCreate()">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-plus-circle"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="16"></line><line x1="8" y1="12" x2="16" y2="12"></line></svg>
            </button>            
        </div>
        <div class="col-md-12">
            <div class="row">
                <div class="col-md-3">
                    <label class="control-label">Entity</label>
                    <multiselect 
                        v-model="entityFilter" 
                        :custom-label="serviceEntityFilter" 
                        :options="entities" 
                        placeholder="Select One" 
                        label="description" 
                        track-by="description" 
                        name="entity_id" 
                        autofocuc="yes"
                        style="margin-top: 23px;"
                        @input="setEntityFilter()"
                    ></multiselect>
                </div>
                <div class="col-md-3">
                    <label class="control-label">Application</label>
                    <multiselect 
                        v-model="applicationFilter" 
                        :custom-label="serviceApplication" 
                        :options="applications" 
                        placeholder="Select One" 
                        label="appl_id" 
                        track-by="appl_id" 
                        name="appl_id" 
                        autofocuc="yes"
                        style="margin-top: 23px;"
                    ></multiselect>
                </div>
                <div class="col-md-2">
                    <label class="control-label">Status</label>
                    <multiselect 
                        v-model="statusFilter" 
                        :custom-label="serviceStatus" 
                        :options="statuses" 
                        placeholder="Select One" 
                        label="value" 
                        track-by="value" 
                        name="value" 
                        autofocuc="yes"
                        style="margin-top: 23px;"
                    ></multiselect>
                </div>
                <div class="col-md-3">
                    <button class="btn btn-info btn-fill float-right" style="margin-top: 24px;float: left;" @click="filter()">
                        Filter
                    </button>
                </div>
                
            </div>
        </div>
        <div class="col-md-12 mt-2">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-lg-12">
                            <table class="table table-striped table-sm table-bordered table-hover display nowrap" id="example" style="width:100%">
                                <thead>
                                    <tr>
                                        <th style="text-align:center !important">Entity</th>
                                        <th style="text-align:center !important">Application</th>
                                        <th style="text-align:center !important">Menu Header</th>
                                        <th style="text-align:center !important">Menu Id</th>
                                        <th style="text-align:center !important">Menu Caption</th>
                                        <th style="text-align:center !important">Route Path</th>
                                        <th style="text-align:center !important">Route Web</th>
                                        <th style="text-align:center !important">Status</th>
                                        <th style="text-align:center !important">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="menu in menus" :key="menu.id">
                                        <td>{{menu.entity_id}}</td>
                                        <td>{{menu.appl_id}}</td>
                                        <td>{{menu.menu_header}}</td>
                                        <td>{{menu.menu_id}}</td>
                                        <td>{{menu.menu_caption}}</td>
                                        <td>{{menu.route_path}}</td>
                                        <td>{{menu.route_web}}</td>
                                        <td>
                                            <label class="switch" v-if="menu.active_flag === 'N'">
                                                <input type="checkbox" v-on:change="deleteMenuItem(menu)">
                                                <span class="slider round"></span>
                                            </label>
                                            <label class="switch" v-else>
                                                <input type="checkbox" v-on:change="deleteMenuItem(menu)" checked>
                                                <span class="slider round"></span>
                                            </label>
                                        </td>
                                        <td>
                                            <button class="btn btn-sm btn-warning" @click="EditModal(menu)">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit-2"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg>
                                            </button>
                                            <!-- <button class="btn btn-sm btn-danger" style="margin-left:5px" @click="deleteMenuItem(menu)">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg>
                                            </button> -->
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="createMenuModal" tabindex="-1" aria-labelledby="createMenuModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="createMenuModalLabel">Tambah Menu Item</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body py-lg-10 px-lg-10">
                    <div class="row g-3 align-items-center">
                        <div class="col-md-3">
                            <label for="entity" class="col-form-label">Entity</label>
                        </div>
                        <div class="col-auto">
                            <multiselect 
                                v-model="entity" 
                                :custom-label="serviceEntity" 
                                :options="entities" 
                                placeholder="Select One" 
                                label="description" 
                                track-by="description" 
                                name="entity_id" 
                                autofocuc="yes"
                                class="form-control"
                                @input="setEntity()"
                            ></multiselect>
                        </div>
                    </div>
                    <div class="row g-3 align-items-center mt-1">
                        <div class="col-md-3">
                            <label for="entity" class="col-form-label">Application</label>
                        </div>
                        <div class="col-auto">
                            <multiselect 
                                v-model="application" 
                                :custom-label="serviceApplication" 
                                :options="applications" 
                                placeholder="Select One" 
                                label="appl_id" 
                                track-by="appl_id" 
                                name="appl_id" 
                                autofocuc="yes"
                                class="form-control"
                                @input="setApplication()"
                            ></multiselect>
                        </div>
                    </div>
                    <div class="row g-3 align-items-center mt-1">
                        <div class="col-md-3">
                            <label for="entity" class="col-form-label">Menu Header</label>
                        </div>
                        <div class="col-auto">
                            <multiselect 
                                v-model="menu_header" 
                                :custom-label="serviceMenuHeader" 
                                :options="menu_headers" 
                                placeholder="Select One" 
                                label="menu_header_id" 
                                track-by="menu_header_id" 
                                name="menu_header_id" 
                                autofocuc="yes"
                                class="form-control"
                            ></multiselect>
                        </div>
                    </div>
                    <div class="row g-3 align-items-center mt-1">
                        <div class="col-md-3">
                            <label for="menuId" class="col-form-label">Menu ID</label>
                        </div>
                        <div class="col-auto">
                            <input type="text" id="menuId" class="form-control" aria-describedby="menuIdHelpInline" v-model="menuId">
                        </div>
                    </div>
                    <div class="row g-3 align-items-center mt-1">
                        <div class="col-md-3">
                            <label for="caption" class="col-form-label">Menu Caption</label>
                        </div>
                        <div class="col-auto">
                            <input type="text" id="caption" class="form-control" aria-describedby="captionHelpInline" v-model="caption">
                        </div>
                    </div>
                    <div class="row g-3 align-items-center mt-1">
                        <div class="col-md-3">
                            <label for="note1" class="col-form-label">Note 1</label>
                        </div>
                        <div class="col-auto">
                            <input type="text" id="note1" class="form-control" aria-describedby="note1HelpInline" v-model="note1">
                        </div>
                    </div>
                    <div class="row g-3 align-items-center mt-1">
                        <div class="col-md-3">
                            <label for="note2" class="col-form-label">Note 2</label>
                        </div>
                        <div class="col-auto">
                            <input type="text" id="note2" class="form-control" aria-describedby="note2HelpInline" v-model="note2">
                        </div>
                    </div>
                    <div class="row g-3 align-items-center mt-1">
                        <div class="col-md-3">
                            <label for="route_path" class="col-form-label">Route Mobile</label>
                        </div>
                        <div class="col-auto">
                            <input type="text" id="route_path" class="form-control" aria-describedby="route_pathHelpInline" v-model="route_path">
                        </div>
                    </div>
                    <div class="row g-3 align-items-center mt-1">
                        <div class="col-md-3">
                            <label for="route_web" class="col-form-label">Route Web</label>
                        </div>
                        <div class="col-auto">
                            <input type="text" id="route_web" class="form-control" aria-describedby="route_webHelpInline" v-model="route_web">
                        </div>
                    </div>
                    <div class="row g-3 align-items-center mt-1">
                        <div class="col-md-3">
                            <label for="status" class="col-form-label">Status</label>
                        </div>
                        <div class="col-auto">
                            <select class="form-select" aria-label="Select" v-model="status">
                                <option value="Y">Aktif</option>
                                <option value="N">Tidak Aktif</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="button" class="btn btn-success" @click="addMenuItem()">Save</button>
                </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="EditMenuItemModal" tabindex="-1" aria-labelledby="EditMenuItemModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="EditMenuItemModalLabel">Edit Menu Item</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body py-lg-10 px-lg-10">
                    <div class="row g-3 align-items-center">
                        <div class="col-md-3">
                            <label for="entity" class="col-form-label">Entity</label>
                        </div>
                        <div class="col-auto">
                            <multiselect 
                                v-model="editEntity" 
                                :custom-label="serviceEntity" 
                                :options="entities" 
                                placeholder="Select One" 
                                label="description" 
                                track-by="description" 
                                name="entity_id" 
                                autofocuc="yes"
                                class="form-control"
                                disabled
                            ></multiselect>
                        </div>
                    </div>
                    <div class="row g-3 align-items-center mt-1">
                        <div class="col-md-3">
                            <label for="entity" class="col-form-label">Application</label>
                        </div>
                        <div class="col-auto">
                            <multiselect 
                                v-model="editApplication" 
                                :custom-label="serviceApplication" 
                                :options="applications" 
                                placeholder="Select One" 
                                label="appl_id" 
                                track-by="appl_id" 
                                name="appl_id" 
                                autofocuc="yes"
                                class="form-control"
                                @input="setApplication()"
                                disabled
                            ></multiselect>
                        </div>
                    </div>
                    <div class="row g-3 align-items-center mt-1">
                        <div class="col-md-3">
                            <label for="entity" class="col-form-label">Menu Header</label>
                        </div>
                        <div class="col-auto">
                            <multiselect 
                                v-model="editMenuHeader" 
                                :custom-label="serviceMenuHeader" 
                                :options="menu_headers" 
                                placeholder="Select One" 
                                label="menu_header_id" 
                                track-by="menu_header_id" 
                                name="menu_header_id" 
                                autofocuc="yes"
                                class="form-control"
                                disabled
                            ></multiselect>
                        </div>
                    </div>
                    <div class="row g-3 align-items-center mt-1">
                        <div class="col-md-3">
                            <label for="editMenuId" class="col-form-label">Menu ID</label>
                        </div>
                        <div class="col-auto">
                            <input type="text" id="editMenuId" class="form-control" aria-describedby="editMenuIdHelpInline" v-model="editMenuId" disabled>
                        </div>
                    </div>
                    <div class="row g-3 align-items-center mt-1">
                        <div class="col-md-3">
                            <label for="editCaption" class="col-form-label">Menu Caption</label>
                        </div>
                        <div class="col-auto">
                            <input type="text" id="editCaption" class="form-control" aria-describedby="editCaptionHelpInline" v-model="editCaption">
                        </div>
                    </div>
                    <div class="row g-3 align-items-center mt-1">
                        <div class="col-md-3">
                            <label for="editNote1" class="col-form-label">Note 1</label>
                        </div>
                        <div class="col-auto">
                            <input type="text" id="editNote1" class="form-control" aria-describedby="editNote1HelpInline" v-model="editNote1">
                        </div>
                    </div>
                    <div class="row g-3 align-items-center mt-1">
                        <div class="col-md-3">
                            <label for="editNote2" class="col-form-label">Note 2</label>
                        </div>
                        <div class="col-auto">
                            <input type="text" id="editNote2" class="form-control" aria-describedby="editNote2HelpInline" v-model="editNote2">
                        </div>
                    </div>
                    <div class="row g-3 align-items-center mt-1">
                        <div class="col-md-3">
                            <label for="editRoutePath" class="col-form-label">Route Mobile</label>
                        </div>
                        <div class="col-auto">
                            <input type="text" id="editRoutePath" class="form-control" aria-describedby="editRoutePathHelpInline" v-model="editRoutePath">
                        </div>
                    </div>
                    <div class="row g-3 align-items-center mt-1">
                        <div class="col-md-3">
                            <label for="editRouteWeb" class="col-form-label">Route Web</label>
                        </div>
                        <div class="col-auto">
                            <input type="text" id="editRouteWeb" class="form-control" aria-describedby="editRouteWebHelpInline" v-model="editRouteWeb">
                        </div>
                    </div>
                    <div class="row g-3 align-items-center mt-1">
                        <div class="col-md-3">
                            <label for="status" class="col-form-label">Status</label>
                        </div>
                        <div class="col-auto">
                            <select class="form-select" aria-label="Select" v-model="editStatus">
                                <option value="Y">Aktif</option>
                                <option value="N">Tidak Aktif</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="button" class="btn btn-success" @click="updateMenuItem()">Save</button>
                </div>
                </div>
            </div>
        </div>
        <snackbar ref="snackbar" baseSize="100px" :holdTime="5000" position="top-center"></snackbar>

      </div>
    </div>
  </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<script>
//Bootstrap and jQuery libraries
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery'; 
import Snackbar from "vuejs-snackbar";
import swal from 'sweetalert';
import Multiselect from 'vue-multiselect'


export default {
    mounted() {
        this.getEntity()
        this.getApplication()
        this.getMenuHeader()
    },
    data(){
        return {
            menus: [],
            fullPage: true, 
            loader : null,
            caption: '',
            description: '',
            status: null,
            editEntity: {},
            editApplication: {},
            editMenuHeader: {},
            editMenuId: '',
            editCaption: '',
            editRoutePath: '',
            editNote1: '',
            editNote2: '',
            editRouteWeb: '',
            dataMenuItem: null,
            entities: [],
            entity: {},
            applications: [],
            application: {},
            route_path: '',
            route_web: '',
            menu_headers: [],
            menu_header: {},
            note1: '',
            note2: '',
            menuId: '',
            status: '',
            editStatus: '',
            entityFilter: '',
            applicationFilter: '',
            statusFilter: '',
            statuses: [
                {
                    desc : 'YA',
                    value : 'Y'
                },
                {
                    desc : 'NO',
                    value : 'N'
                }
            ]
        }
    },
    components: {
		Snackbar, Multiselect
	},
    methods:{
        clear(){
            this.caption = ''
            this.description = ''
            this.status = null
            this.entity = ''
            this.application = ''
            this.route_path = ''
            this.route_web = ''
            this.menu_header = ''
            this.note1 = ''
            this.note2 = ''
            this.menuId = ''
        },
        loaderLoad(){
            this.loader = this.$loading.show({
                // Optional parameters
                container: this.fullPage ? null : this.$refs.formContainer,
                color: '#0a58ca',
                loader: 'dots',
                width: 64,
                height: 64,
                backgroundColor: '#222223',
                opacity: 0.5,
                zIndex: 999,
            });
        },
        serviceStatus({desc}){
            return desc;
        },
        serviceEntityFilter({entity_id}){
            return entity_id;
        },
        serviceEntity (option) {
            return `${option.entity_id} - ${option.description}`
        },
        setEntity(){
            axios.get(`${process.env.VUE_APP_URL}/api/master/aplikasi?entity_id=${this.entity.entity_id}&status=Y`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.applications = res.data
            });
        },
        setEntityFilter(){
            axios.get(`${process.env.VUE_APP_URL}/api/master/aplikasi?entity_id=${this.entityFilter.entity_id}&status=Y`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.applications = res.data
            });
        },
        getEntity() {    
            axios.get(`${process.env.VUE_APP_URL}/api/master/entity?status=1`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.entities = res.data
            });
        },
        serviceApplication({appl_id}){
			return appl_id
		},
        setApplication(){
            axios.get(`${process.env.VUE_APP_URL}/api/master/menu_header?entity_id=${this.entity.entity_id}&appl_id=${this.application.appl_id}&active_flag=Y`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.menu_headers = res.data
            });
        },
        getApplication() {    
            axios.get(`${process.env.VUE_APP_URL}/api/master/aplikasi?status=Y`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.applications = res.data
            });
        },
        serviceMenuHeader({menu_caption}){
			return menu_caption
		},
        getMenuHeader() {    
            axios.get(`${process.env.VUE_APP_URL}/api/master/menu_header?active_flag=Y`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.menu_headers = res.data
            });
        },
        filter(){
            this.loaderLoad();
            var url = ''
            if (this.entityFilter.entity_id === undefined && this.applicationFilter.appl_id === undefined && this.statusFilter.value === undefined) {
                this.loader.hide();
                return this.getMenuItem()
            } else if(this.entityFilter.entity_id != undefined && this.applicationFilter.appl_id != undefined && this.statusFilter.value != undefined){
                url = `${process.env.VUE_APP_URL}/api/menu?entity_id=${this.entityFilter.entity_id}&appl_id=${this.applicationFilter.appl_id}&active_flag=${this.statusFilter.value}`
            } else if(this.entityFilter.entity_id != undefined && this.applicationFilter.appl_id == undefined && this.statusFilter.value == undefined){
                url = `${process.env.VUE_APP_URL}/api/menu?entity_id=${this.entityFilter.entity_id}`
            } else if(this.entityFilter.entity_id == undefined && this.applicationFilter.appl_id != undefined && this.statusFilter.value == undefined){
                url = `${process.env.VUE_APP_URL}/api/menu?appl_id=${this.applicationFilter.appl_id}`
            } else if(this.entityFilter.entity_id == undefined && this.applicationFilter.appl_id == undefined && this.statusFilter.value != undefined){
                url = `${process.env.VUE_APP_URL}/api/menu?active_flag=${this.statusFilter.value}`
            } else if(this.entityFilter.entity_id != undefined && this.applicationFilter.appl_id != undefined && this.statusFilter.value == undefined){
                url = `${process.env.VUE_APP_URL}/api/menu?entity_id=${this.entityFilter.entity_id}&appl_id=${this.applicationFilter.appl_id}`
            } else if(this.entityFilter.entity_id != undefined && this.applicationFilter.appl_id == undefined && this.statusFilter.value != undefined){
                url = `${process.env.VUE_APP_URL}/api/menu?entity_id=${this.entityFilter.entity_id}&active_flag=${this.statusFilter.value}`
            }

            axios.get(url, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.loader.hide();

                $('#example').DataTable().destroy();

                this.menus = res.data
                this.$nextTick(() => {
                    $("#example").DataTable({
                        "responsive": true,
                        "autoWidth": false,
                        "ordering": false,
                        "info": true,
                        "pageLength": 10,
                        "bDestroy": true,
                        "recordsFiltered":10,
                        "destroy": true,
                    });
                });

                this.entityFilter = ''
                this.statusFilter = ''
                this.applicationFilter = ''
            });
        },
        getMenuItem() {    
            this.loaderLoad();

            axios.get(`${process.env.VUE_APP_URL}/api/menu`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.loader.hide();
                $('#example').DataTable().destroy();

                this.menus = res.data
                this.$nextTick(() => {
                    $("#example").DataTable({
                        "responsive": true,
                        "autoWidth": false,
                        "ordering": false,
                        "info": true,
                        "pageLength": 10,
                        "bDestroy": true,
                        "recordsFiltered":10,
                        "destroy": true,
                    });
                });
            });
        },
        showModalCreate(){
            $("#createMenuModal").modal('show');
        },
        addMenuItem(){
            $("#createMenuModal").modal('hide');
            this.loaderLoad();

            if (this.entity.entity_id == undefined && this.application.appl_id  == undefined && this.menu_header.menu_header_id  == undefined && this.menuId == '' && this.caption == '' && this.note1 == '' && this.note2 == '' && this.route_web == '') {
                this.loader.hide();
                return this.$refs.snackbar.error("Mohon diisi entity, application, menu id, caption, note dan route web");
            } else if(this.entity.entity_id == undefined) {
                this.loader.hide();
                return this.$refs.snackbar.error("Mohon diisi entity anda");
            } else if(this.application.appl_id == undefined) {
                this.loader.hide();
                return this.$refs.snackbar.error("Mohon diisi aplikasi anda");
            } else if(this.menu_header.menu_header_id == undefined) {
                this.loader.hide();
                return this.$refs.snackbar.error("Mohon diisi menu header anda");
            } else if(this.menuId == ''){
                this.loader.hide();
                return this.$refs.snackbar.error("Mohon diisi menu ID anda");
            } else if(this.caption == ''){
                this.loader.hide();
                return this.$refs.snackbar.error("Mohon diisi caption anda");
            } else if(this.note1 == ''){
                this.loader.hide();
                return this.$refs.snackbar.error("Mohon diisi note1 anda");
            } else if(this.note2 == ''){
                this.loader.hide();
                return this.$refs.snackbar.error("Mohon diisi note2 anda");
            } else if(this.route_web == ''){
                this.loader.hide();
                return this.$refs.snackbar.error("Mohon diisi route web anda");
            }

            axios.post(`${process.env.VUE_APP_URL}/api/menu`, {
                'entity_id': this.entity.entity_id,
                'appl_id': this.application.appl_id,
                'menu_header_id': this.menu_header.menu_header_id,
                'menu_id' : this.menuId,
                'menu_caption' : this.caption,
                'note1' : this.note1,
                'note2' : this.note2,
                'route_path' : this.route_path,
                'route_web' : this.route_web,
                'status' : this.status
            }, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            }).then(res => {
                this.loader.hide();
                swal("Berhasil tambah Menu", {icon: "success", button: false})
                $('#example').DataTable().destroy();
                this.getMenuItem();
                this.clear()
            }).catch(err => {
                this.loader.hide();
                swal(err.response.data.message, {icon: "error", button: false})
                console.log(err.response);
            })
        },
        EditModal(data){
            this.dataMenuItem = data
            this.editEntity = data
            this.editApplication = data
            this.editMenuHeader = data
            this.editMenuId = data.menu_id
            this.editCaption = data.menu_caption
            this.editRoutePath = data.route_path
            this.editNote1 = data.note1
            this.editNote2 = data.note2
            this.editRouteWeb = data.route_web
            this.editStatus = data.active_flag

            $("#EditMenuItemModal").modal('show');
        },
        updateMenuItem(){
            $("#EditMenuItemModal").modal('hide');
            this.loaderLoad();

            axios.put(`${process.env.VUE_APP_URL}/api/menu/update`, {
                'entity_id': this.editEntity.entity_id,
                'appl_id': this.editApplication.appl_id,
                'menu_header_id': this.editMenuHeader.menu_header_id,
                'menu_id' : this.editMenuId,
                'menu_caption' : this.editCaption,
                'note1' : this.editNote1,
                'note2' : this.editNote2,
                'route_path' : this.editRoutePath,
                'route_web' : this.editRouteWeb,
                'seq_id' : this.dataMenuItem.seq_id,
                'status' : this.editStatus
            }, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            }).then(res => {
                this.loader.hide();
                $('#example').DataTable().destroy();
                this.getMenuItem();
                swal("Berhasil edit menu", {icon: "success", button: false})

            }).catch(err => {
                this.loader.hide();
                swal(err.response.data.message, {icon: "error", button: false})
                console.log(err.response);
            })
        },
        deleteMenuItem(menu){
            if (menu.active_flag === 'Y') {
                this.updateStatus(menu, 'N')
            } else{
                this.updateStatus(menu, 'Y')
            }
        },
        updateStatus(menu, status){
            axios.put(`${process.env.VUE_APP_URL}/api/menu/updateMenuStatus`, {
                'entity_id': menu.entity_id,
                'appl_id' : menu.appl_id,
                'menu_header_id' : menu.menu_header_id,
                'menu_id' : menu.menu_id,
                'status': status
            }, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            }).then(res => {
                $('#example').DataTable().destroy();
                this.getMenuItem();
                swal("Berhasil update status", {icon: "success", button: false})
            }).catch(err => {
                swal(err.response.data.message, {icon: "error", button: false})
            })
        }
    },
    created() {
        this.getMenuItem()
    }
}
</script>
<style>

</style>