import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    user: {
			token: "",
			isLoggedIn: false
		},
    isLoading: true,
	display:'none'
  },
  mutations: {
    USER_TOKEN (state, payload) {
			state.user.token = payload
			state.user.isLoggedIn = true
		},
    USER_LOGOUT (state, payload) {
			if(payload == true) {
				state.user.token = ""
				state.user.isLoggedIn = false
			}
		}
  },
  actions: {
    loginUser({ commit }, data) {
			return new Promise((resolve, reject) => {
				axios.post(`${process.env.VUE_APP_URL}/api/login`, data)
				.then(res => {
					const token = res.data.data.access_token
					axios.defaults.headers.common["Authorization"] = "Bearer " + token
					commit("USER_TOKEN", token);
					resolve(res)
				})
				.catch(err => {
					reject(err)
				})
			})
		},
    logoutUser({ commit }) {
			return new Promise((resolve, reject) => {
				axios.get(`${process.env.VUE_APP_URL}/api/logout`, { 
					headers: { Authorization: `Bearer ${this.getters.isTokenUser}` } 
				})
				.then(res => {
					delete axios.defaults.headers.common['Authorization']
					commit('USER_LOGOUT', true)
					resolve(res)
				})
				.catch(err => {
					reject(err)
				})
			})
		}
  },
  modules: {},
  getters: {
		isUserLoggedIn: state => !!state.user.token,
		isTokenUser: state => state.user.token
	}
});
