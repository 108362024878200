<template>
    <div class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-10">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><router-link :to="{path:'/admin/'}">Dashboard</router-link></li>
                            <li class="breadcrumb-item"><a href="#">Master</a></li>
                            <li class="breadcrumb-item active" aria-current="page">Application</li>
                        </ol>
                    </nav>
                </div>
                <div class="col-lg-2">
                    <button class="btn btn-success btn-sm" style="float: right;" @click="showModalCreate()">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-plus-circle"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="16"></line><line x1="8" y1="12" x2="16" y2="12"></line></svg>
                    </button>            
                </div>
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-3">
                            <label class="control-label">Entity</label>
                            <multiselect 
                                v-model="entityFilter" 
                                :custom-label="serviceEntityFilter" 
                                :options="entities" 
                                placeholder="Select One" 
                                label="description" 
                                track-by="description" 
                                name="entity_id" 
                                autofocuc="yes"
                                style="margin-top: 23px;"
                            ></multiselect>
                        </div>
                        <div class="col-md-2">
                            <label class="control-label">Status</label>
                            <multiselect 
                                v-model="statusFilter" 
                                :custom-label="serviceStatus" 
                                :options="statuses" 
                                placeholder="Select One" 
                                label="value" 
                                track-by="value" 
                               
                                name="value" 
                                autofocuc="yes"
                                style="margin-top: 23px;"
                            ></multiselect>
                        </div>
                        <div class="col-md-3">
                            <button class="btn btn-info btn-fill float-right" style="margin-top: 24px;float: left;" @click="filter()">
                                Filter
                            </button>
                        </div>
                        
                    </div>
                </div>
                <div class="col-md-12 mt-2">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-lg-12">
                                    <table class="table table-striped table-sm table-bordered table-hover display nowrap" id="example" style="width:100%">
                                        <thead>
                                            <tr>
                                                <th style="text-align:center !important">Entity</th>
                                                <th style="text-align:center !important">Application</th>
                                                <th style="text-align:center !important">Application Versi</th>
                                                <th style="text-align:center !important">Status</th>
                                                <th style="text-align:center !important">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="application in applications" :key="application.id">
                                                <td>{{application.entity_id }}</td>
                                                <td>{{application.appl_id }}</td>
                                                <td>{{application.appl_ver }}</td>
                                                <td>
                                                    <label class="switch" v-if="application.active_flag === 'N'">
                                                        <input type="checkbox" v-on:change="deleteApplication(application)">
                                                        <span class="slider round"></span>
                                                    </label>
                                                    <label class="switch" v-else>
                                                        <input type="checkbox" v-on:change="deleteApplication(application)" checked>
                                                        <span class="slider round"></span>
                                                    </label>
                                                </td>
                                                <td>
                                                    <!-- Edit -->
                                                    <button class="btn btn-sm btn-warning" @click="EditModal(application)">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit-2"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg>
                                                    </button>
                                                    <!-- Delete -->
                                                    <!-- <button class="btn btn-sm btn-danger" style="margin-left:5px" @click="deleteApplication(application)">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg>
                                                    </button> -->
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal fade" id="createApplicationModal" tabindex="-1" aria-labelledby="createApplicationModalLabel" aria-hidden="true">
                    <div class="modal-dialog">
                        <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="createApplicationModalLabel">Tambah Application</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body py-lg-10 px-lg-10">
                            <div class="row g-3 align-items-center">
                                <div class="col-md-3">
                                    <label for="entity" class="col-form-label">Entity</label>
                                </div>
                                <div class="col-auto">
                                    <multiselect 
                                        v-model="entity" 
                                        :custom-label="serviceEntity" 
                                        :options="entities" 
                                        placeholder="Select One" 
                                        label="description" 
                                        track-by="description" 
                                        :allow-empty="false" 
                                        :showLabels="false" 
                                        name="entity_id" 
                                        autofocuc="yes"
                                        class="form-control"
                                    ></multiselect>
                                </div>
                            </div>
                            <div class="row g-3 align-items-center mt-1">
                                <div class="col-md-3">
                                    <label for="name" class="col-form-label">Name</label>
                                </div>
                                <div class="col-auto">
                                    <input type="text" id="name" class="form-control" aria-describedby="nameHelpInline" v-model="name">
                                </div>
                            </div>
                            <div class="row g-3 align-items-center mt-1">
                                <div class="col-md-3">
                                    <label for="versi" class="col-form-label">Versi</label>
                                </div>
                                <div class="col-auto">
                                    <input type="text" id="versi" class="form-control" aria-describedby="versiHelpInline" v-model="versi">
                                </div>
                            </div>
                            <div class="row g-3 align-items-center mt-1">
                                <div class="col-md-3">
                                    <label for="note" class="col-form-label">Note</label>
                                </div>
                                <div class="col-auto">
                                    <input type="text" id="note" class="form-control" aria-describedby="noteHelpInline" v-model="note">
                                </div>
                            </div>
                            <div class="row g-3 align-items-center mt-1">
                                <div class="col-md-3">
                                    <label for="status" class="col-form-label">Status</label>
                                </div>
                                <div class="col-auto">
                                    <select class="form-select" aria-label="Select" v-model="status">
                                        <option value="Y">Aktif</option>
                                        <option value="N">Tidak Aktif</option>
                                    </select>
                                </div>
                            </div>
                            <div class="row g-3 align-items-center mt-1">
                                <div class="col-md-3">
                                    <label for="link_id" class="col-form-label">Link ID</label>
                                </div>
                                <div class="col-auto">
                                    <input type="text" id="link_id" class="form-control" aria-describedby="link_idHelpInline" v-model="link_id">
                                </div>
                            </div>
                            <div class="row g-3 align-items-center mt-1">
                                <div class="col-md-3">
                                    <label for="link_id_ol" class="col-form-label">Link ID OL</label>
                                </div>
                                <div class="col-auto">
                                    <input type="text" id="link_id_ol" class="form-control" aria-describedby="link_id_olHelpInline" v-model="link_id_ol">
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" class="btn btn-success" @click="addApplication()">Save</button>
                        </div>
                        </div>
                    </div>
                </div>
                <div class="modal fade" id="EditApplicationModal" tabindex="-1" aria-labelledby="EditApplicationModalLabel" aria-hidden="true">
                    <div class="modal-dialog">
                        <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="EditApplicationModalLabel">Edit Application</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body py-lg-10 px-lg-10">
                            <div class="row g-3 align-items-center">
                                <div class="col-md-3">
                                    <label for="entity" class="col-form-label">Entity</label>
                                </div>
                                <div class="col-auto">
                                    <multiselect 
                                        v-model="edit_entity" 
                                        :custom-label="serviceEntity" 
                                        :options="entities" 
                                        placeholder="Select One" 
                                        label="description" 
                                        track-by="description" 
                                        :allow-empty="false" 
                                        :showLabels="false" 
                                        name="entity_id" 
                                        autofocuc="yes"
                                        class="form-control"
                                        disabled
                                    ></multiselect>
                                </div>
                            </div>
                            <div class="row g-3 align-items-center mt-1">
                                <div class="col-md-3">
                                    <label for="name" class="col-form-label">Name</label>
                                </div>
                                <div class="col-auto">
                                    <input type="text" id="name" class="form-control" aria-describedby="nameHelpInline" v-model="edit_name" disabled>
                                </div>
                            </div>
                            <div class="row g-3 align-items-center mt-1">
                                <div class="col-md-3">
                                    <label for="versi" class="col-form-label">Versi</label>
                                </div>
                                <div class="col-auto">
                                    <input type="text" id="versi" class="form-control" aria-describedby="versiHelpInline" v-model="edit_versi">
                                </div>
                            </div>
                            <div class="row g-3 align-items-center mt-1">
                                <div class="col-md-3">
                                    <label for="note" class="col-form-label">Note</label>
                                </div>
                                <div class="col-auto">
                                    <input type="text" id="note" class="form-control" aria-describedby="noteHelpInline" v-model="edit_note">
                                </div>
                            </div>
                            <div class="row g-3 align-items-center mt-1">
                                <div class="col-md-3">
                                    <label for="status" class="col-form-label">Status</label>
                                </div>
                                <div class="col-auto">
                                    <select class="form-select" aria-label="Select" v-model="edit_status">
                                        <option value="Y">Aktif</option>
                                        <option value="N">Tidak Aktif</option>
                                    </select>
                                </div>
                            </div>
                            <div class="row g-3 align-items-center mt-1">
                                <div class="col-md-3">
                                    <label for="link_id" class="col-form-label">Link ID</label>
                                </div>
                                <div class="col-auto">
                                    <input type="text" id="link_id" class="form-control" aria-describedby="link_idHelpInline" v-model="edit_link_id">
                                </div>
                            </div>
                            <div class="row g-3 align-items-center mt-1">
                                <div class="col-md-3">
                                    <label for="link_id_ol" class="col-form-label">Link ID OL</label>
                                </div>
                                <div class="col-auto">
                                    <input type="text" id="link_id_ol" class="form-control" aria-describedby="link_id_olHelpInline" v-model="edit_link_id_ol">
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" class="btn btn-success" @click="updateApplication()">Save</button>
                        </div>
                        </div>
                    </div>
                </div>
                <snackbar ref="snackbar" baseSize="100px" :holdTime="5000" position="top-center"></snackbar>
            </div>
        </div>
    </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<script>
//Bootstrap and jQuery libraries
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery'; 
import Snackbar from "vuejs-snackbar";
import swal from 'sweetalert';
import Multiselect from 'vue-multiselect'

export default {
    mounted() {
        this.getEntity()
    },
    data(){
        return {
            fullPage: true, 
            loader : null,
            applications: [],
            entities: [],
            entity: '',
            name: '',
            versi: '',
            note: '',
            status: null,
            link_id: '',
            link_id_ol: '',
            dataApplication: null,
            edit_name: '',
            edit_versi: '',
            edit_note: '',
            edit_status: null,
            edit_link_id: '',
            edit_link_id_ol: '',
            edit_entity: '',
            entityFilter: '',
            statusFilter: '',
            statuses: [
                {
                    desc : 'YA',
                    value : 'Y'
                },
                {
                    desc : 'NO',
                    value : 'N'
                }
            ]
        }
    },
    components: {
		Snackbar, Multiselect
	},
    methods:{
        loaderLoad(){
            this.loader = this.$loading.show({
                // Optional parameters
                container: this.fullPage ? null : this.$refs.formContainer,
                color: '#0a58ca',
                loader: 'dots',
                width: 64,
                height: 64,
                backgroundColor: '#222223',
                opacity: 0.5,
                zIndex: 999,
            });
        },
        clear(){
            this.entity = ''
            this.name = ''
            this.versi = ''
            this.note = ''
            this.status = null
            this.link_id = ''
            this.link_id_ol = ''
        },
        serviceStatus({desc}){
            return desc;
        },
        serviceEntityFilter({entity_id}){
            return entity_id;
        },
        serviceEntity (option) {
            return `${option.entity_id} - ${option.description}`
        },
        getEntity() {    
            axios.get(`${process.env.VUE_APP_URL}/api/master/entity?status=1`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.entities = res.data
            });
        },
        getApplication(){
            this.loaderLoad();

            axios.get(`${process.env.VUE_APP_URL}/api/master/aplikasi`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.loader.hide();
                $('#example').DataTable().destroy();
                
                this.applications = res.data
                this.$nextTick(() => {
                    $("#example").DataTable({
                        "responsive": true,
                        "autoWidth": false,
                        "ordering": false,
                        "info": true,
                        "pageLength": 10,
                        "bDestroy": true,
                        "recordsFiltered":10,
                        "destroy": true,
                    });
                });
            });
        },
        filter(){
            this.loaderLoad();
            var url = ''
            if (this.entityFilter.entity_id === undefined && this.statusFilter.value === undefined) {
                this.loader.hide();
                return this.getApplication()
            } else if(this.entityFilter.entity_id != undefined && this.statusFilter.value != undefined){
                url = `${process.env.VUE_APP_URL}/api/master/aplikasi?entity_id=${this.entityFilter.entity_id}&status=${this.statusFilter.value}`
            } else if(this.entityFilter.entity_id != undefined && this.statusFilter.value == undefined){
                url = `${process.env.VUE_APP_URL}/api/master/aplikasi?entity_id=${this.entityFilter.entity_id}`
            } else if(this.entityFilter.entity_id == undefined && this.statusFilter.value != undefined){
                url = `${process.env.VUE_APP_URL}/api/master/aplikasi?status=${this.statusFilter.value}`
            }

            axios.get(url, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.loader.hide();

                $('#example').DataTable().destroy();

                this.applications = res.data
                this.$nextTick(() => {
                    $("#example").DataTable({
                        "responsive": true,
                        "autoWidth": false,
                        "ordering": false,
                        "info": true,
                        "pageLength": 10,
                        "bDestroy": true,
                        "recordsFiltered":10,
                        "destroy": true,
                    });
                });

                this.entityFilter = ''
                this.statusFilter = ''
            });
        },
        addApplication(){
            console.log(this.entity.entity_id);
            $("#createApplicationModal").modal('hide');
            this.loaderLoad();

            if (this.entity.entity_id == undefined && this.name == '' && this.versi == '' && this.status == null) {
                this.loader.hide();
                return this.$refs.snackbar.error("Mohon diisi entity, nama, versi dan status");
            } else if(this.entity.entity_id == undefined){
                this.loader.hide();
                return this.$refs.snackbar.error("Mohon diisi entity anda");
            } else if(this.name == ''){
                this.loader.hide();
                return this.$refs.snackbar.error("Mohon diisi nama aplikasi anda");
            } 
            else if(this.versi == ''){
                this.loader.hide();
                return this.$refs.snackbar.error("Mohon diisi versi aplikasi");
            } 
            else if(this.status == null){
                this.loader.hide();
                return this.$refs.snackbar.error("Mohon diisi status entity");
            } 

            axios.post(`${process.env.VUE_APP_URL}/api/master/aplikasi`, {
                'entity_id': this.entity.entity_id,
                'appl_id': this.name,
                'appl_ver': this.versi,
                'note1': this.note,
                'active_flag': this.status,
                'link_id': this.link_id,
                'link_id_ol': this.link_id_ol,
            }, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            }).then(res => {
                this.loader.hide();
                $('#example').DataTable().destroy();
                this.getApplication();
                this.clear()
                swal("Berhasil tambah application", {icon: "success", button: false})
            }).catch(err => {
                console.log(err.response);
                this.loader.hide();
                return this.$refs.snackbar.error(err.response.data.message);
            })
        },
        updateApplication(){
            $("#EditApplicationModal").modal('hide');
            this.loaderLoad();

            axios.put(`${process.env.VUE_APP_URL}/api/master/aplikasi/update`, {
                'entity_id': this.edit_entity.entity_id,
                'appl_id': this.edit_name,
                'appl_ver': this.edit_versi,
                'note1': this.edit_note,
                'active_flag': this.edit_status,
                'link_id': this.edit_link_id,
                'link_id_ol': this.edit_link_id_ol,
            }, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            }).then(res => {
                this.loader.hide();
                $('#example').DataTable().destroy();
                this.getApplication();
                swal("Berhasil edit application", {icon: "success", button: false})
            }).catch(err => {
                this.loader.hide();
                swal(err.response.data.message, {icon: "error", button: false})
            })
        },
        deleteApplication(aplikasi){
            if (aplikasi.active_flag === 'Y') {
                this.updateStatus(aplikasi, 'N')
            } else{
                this.updateStatus(aplikasi, 'Y')
            }
        },
        updateStatus(aplikasi, status){
            axios.put(`${process.env.VUE_APP_URL}/api/master/aplikasi/updateAplikasiStatus`, {
                'entity_id': aplikasi.entity_id,
                'appl_id' : aplikasi.appl_id,
                'status': status
            }, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            }).then(res => {
                $('#example').DataTable().destroy();
                this.getApplication();
                swal("Berhasil update status", {icon: "success", button: false})
            }).catch(err => {
                swal(err.response.data.message, {icon: "error", button: false})
            })
        },
        EditModal(data){
            this.dataApplication = data
            this.edit_entity = data
            this.edit_name = data.appl_id
            this.edit_versi = data.appl_ver
            this.edit_note = data.note1
            this.edit_status = data.active_flag
            this.edit_link_id = data.link_id
            this.edit_link_id_ol = data.link_id_ol
            $("#EditApplicationModal").modal('show');
        },
        showModalCreate(){
            $("#createApplicationModal").modal('show');
        }
    },
    created() {
        this.getApplication()
    }
}
</script>
