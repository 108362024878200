<template>
  <div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-10">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><router-link :to="{path:'/admin/'}">Dashboard</router-link></li>
                    <li class="breadcrumb-item"><a href="#">Master</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Wa Center</li>
                </ol>
            </nav>
        </div>
        <div class="col-lg-2">
            <button class="btn btn-success btn-sm" style="float: right;" @click="showModalCreate()">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-plus-circle"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="16"></line><line x1="8" y1="12" x2="16" y2="12"></line></svg>
            </button>            
        </div>
        <div class="col-md-12">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-lg-12">
                            <table class="table table-striped table-sm table-bordered table-hover display nowrap" id="example" style="width:100%">
                                <thead>
                                    <tr>
                                        <th style="text-align:center !important">Entity Id</th>
                                        <th style="text-align:center !important">Appl Id</th> 
                                        <th style="text-align:center !important">Event Id</th>
                                        <th style="text-align:center !important">Group Id</th>
                                        <th style="text-align:center !important">active flag</th>
                                         
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="env_var in env_vars" :key="env_var.id">
                                      <td>{{env_var.entity_id}}</td>
                                        <td>{{env_var.appl_id}}</td>
                                        <td>{{env_var.event_id}}</td>
                                        <td>{{env_var.group_id}}</td>
                                        <td>
                                            <label class="switch">
                                                <input type="checkbox" v-on:change="deleteEnvVar(env_var)" :checked="env_var.active_flag === 'N' ? false : true">
                                                <span class="slider round"></span>
                                            </label>
                                        </td>
                                        <!-- <td>
                                            <button class="btn btn-sm btn-warning" @click="EditModal(env_var)">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit-2"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg>
                                            </button>
                                        </td> -->
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="createEnvVarModal" tabindex="-1" aria-labelledby="createEnvVarModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="createEnvVarModalLabel">Tambah Wa Center</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body py-lg-10 px-lg-10">
                    <div class="row g-3 align-items-center">
                        <div class="col-md-3">
                            <label for="entity" class="col-form-label">Entity Id</label><!-- Multi SelecT -->
                        </div>
                        <div class="col-auto">
                            <multiselect 
                                v-model="entity" 
                                :custom-label="serviceEntity" 
                                :options="entities" 
                                placeholder="Select One" 
                                label="description" 
                                track-by="description" 
                                :allow-empty="false" 
                                :showLabels="false" 
                                name="entity_id" 
                                autofocus="yes"
                                class="form-control"
                                @input="setEntity()"
                            ></multiselect>
                        </div>
                    </div>            
                  <div class="row g-3 align-items-center mt-1">
                        <div class="col-md-3">
                            <label for="appl_id" class="col-form-label">Appl Id</label>
                        </div>
                           <div class="col-auto">
                            <multiselect 
                                v-model="applid" 
                                :custom-label="serviceApplpwa" 
                                :options="applications"
                                placeholder="Select One" 
                                label="appl_id" 
                                track-by="appl_id" 
                                :allow-empty="false" 
                                :showLabels="false" 
                                name="appl_id" 
                                autofocus="yes"
                                class="form-control"
                                @input="setApplEntity()"
                            ></multiselect>
                        </div>
                    </div>                      
                     <div class="row g-3 align-items-center mt-1">
                        <div class="col-md-3">
                            <label for="event_id" class="col-form-label">Event Id</label>
                        </div>
                          <div class="col-auto">
                            <multiselect 
                                v-model="eventid" 
                                :custom-label="serviceEventpwa" 
                                :options="groupevent"
                                placeholder="Select One" 
                                label="event_id" 
                                track-by="event_id" 
                                :allow-empty="false" 
                                :showLabels="false" 
                                name="event_id" 
                                autofocus="yes"
                                class="form-control"
                                @input="setGroupEntity()"
                            ></multiselect>
                        </div>
                    </div>
                     <div class="row g-3 align-items-center mt-1">
                        <div class="col-md-3">
                            <label for="group_id" class="col-form-label">Group Id</label>
                        </div>
                         <div class="col-auto">
                            <multiselect 
                                v-model="groupid" 
                                :custom-label="serviceGroupwa" 
                                :options="groupidd"
                                placeholder="Select One" 
                                label="group_id" 
                                track-by="group_id" 
                                :allow-empty="false" 
                                :showLabels="false" 
                                name="group_id" 
                                autofocus="yes"
                                class="form-control"
                            ></multiselect>
                        </div>
                    </div>  
                    <div class="row g-3 align-items-center mt-1">
                        <div class="col-md-3">
                           <label for="status" class="col-form-label">Status</label>
                        </div>
                        <div class="col-auto">
                           <select class="form-select" aria-label="Select" v-model="active_flag">
                                <option value="Y">Aktif</option>
                                <option value="N">Tidak Aktif</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="button" class="btn btn-success" @click="addEnvVariable()">Save</button>
                </div>
                </div>
            </div>
        </div>
          <!-- <div class="modal fade" id="EditEntityModal" tabindex="-1" aria-labelledby="EditEntityModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="EditEntityModalLabel">Edit Wa Event</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body py-lg-10 px-lg-10">
                    <div class="row g-3 align-items-center">
                        <div class="col-md-3">
                            <label for="entity_id" class="col-form-label">Entity ID</label>
                        </div>
                        <div class="col-auto">
                            <input type="text" class="form-control" aria-describedby="entity_idHelpInline" v-model="editentity_id" disabled>
                        </div>
                    </div>
                    <div class="row g-3 align-items-center mt-1">
                        <div class="col-md-3">
                            <label for="appl_id" class="col-form-label">Event Name</label>
                        </div>
                        <div class="col-auto">
                            <input type="text" class="form-control" aria-describedby="appl_idHelpInline" v-model="editappl_id" disabled>
                        </div>
                    </div>
                    <div class="row g-3 align-items-center mt-1">
                        <div class="col-md-3">
                            <label for="active_flag" class="col-form-label">Status</label>
                        </div>
                        <div class="col-auto">
                            <select class="form-select" aria-label="Select" v-model="editactive_flag">
                                <option value="1">Aktif</option>
                                <option value="0">Tidak Aktif</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="button" class="btn btn-success" @click="updateEnvVar()">Save</button>
                </div>
                </div>
            </div>
        </div> -->
                </div>
            </div>
        </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<script>
//Bootstrap and jQuery libraries
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery'; 
import Snackbar from "vuejs-snackbar";
import swal from 'sweetalert';
import Multiselect from 'vue-multiselect'


export default {
    mounted() {
        this.getEntity()
        this.getApplication()
        this.getVariabel()
        this.getWaAppl()
        // this.getEventid()
        // this.getGroupid()
    },
    data(){
        return {
            env_vars: [],
            env_var: {},
            fullPage: true, 
            loader : null,
            variabelId: '',
            status: null,
            active_flag:null,
            type: null,
            entities: [],
            entity: {},
            applications: [],
            groupmastersss:[],
            groupevent:[],
            application: {},
            route_path: '',
            entity_id:'',
            appl_id:'',
            event_id:'',
            applid:'',
            eventid:'',
            groupidd:[],
            groupid:'',
            group_id:'',
            active_flag:'',
            note1: '',
            editentity_id:'',
            editactive_flag:'',
            editappl_id:'',
            event_name:'',
            env_var_selects: [],
            env_var_select: {},
            var_value: '',
            groups: [],
            group: {},
            users: [],
            user:{},
            editentity: {},
            editApplication: {},
            edit_env_var_select: {},
            editGroup: {},
            editUser: {},
            editType: '',
            editVarValue: '',
            editNote1: '',
            editStatus: '',
            dataEnvVar: null,
            dataTableVar: null,
            checked: null
        }
    },
    components: {
		Snackbar, Multiselect
	},
    methods:{
        loaderLoad(){
            this.loader = this.$loading.show({
                // Optional parameters
                container: this.fullPage ? null : this.$refs.formContainer,
                color: '#0a58ca',
                loader: 'dots',
                width: 64,
                height: 64,
                backgroundColor: '#222223',
                opacity: 0.5,
                zIndex: 999,
            });
        },
        clear(){
            this.entity = ''
            this.entity_id = ''
            this.appl_id = ''
            this.event_id = ''
            this.group_id = ''
            this.active_flag =''
            this.application = ''
            this.env_var_select = ''
            this.group = ''
            this.user = ''
            this.created_at = ''
            this.type = ''
            this.var_value = ''
            this.note1 = ''
            this.status = ''
            this.event_name = ''
            this.active_flag = null
           
        },
        serviceEntity (option) {
            return `${option.entity_id} - ${option.description}`
        },
        getVariabel(){
            axios.get(`${process.env.VUE_APP_URL}/api/master/env_var?active_flag=Y`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.env_var_selects = res.data
            });
        },
        setVariabel(){
            axios.get(`${process.env.VUE_APP_URL}/api/master/env_var?appl_id=${this.application.appl_id}&active_flag=Y`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.env_var_selects = res.data
                this.getGroupUser()
                this.getGroup()
            });
        },
        getGroupUser(){
            axios.get(`${process.env.VUE_APP_URL}/api/group?entity_id=${this.entity.entity_id}&appl_id=${this.application.appl_id}&active_flag=Y`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                // this.loader.hide();
                this.users = res.data
            });
        },
        getGroup(){
            axios.get(`${process.env.VUE_APP_URL}/api/group/select?entity_id=${this.entity.entity_id}&appl_id=${this.application.appl_id}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.groups = res.data
            });
        },
        serviceGroupID({group_id}){
            return group_id;
        },
        serviceUserID({user_id2}){
            return user_id2;
        },
        setEntity(){
            axios.get(`${process.env.VUE_APP_URL}/api/master/aplikasi?entity_id=${this.entity.entity_id}&status=Y`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.applications = res.data
            });
        },
        setApplEntity(){
            axios.get(`${process.env.VUE_APP_URL}/api/wa/event?entity_id=${this.entity.entity_id}&appl_id=${this.applid.appl_id}&status=Y`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.groupevent = res.data.data
            });
        },
        setGroupEntity(){
            axios.get(`${process.env.VUE_APP_URL}/api/wa/group?entity_id=${this.entity.entity_id}&appl_id=${this.applid.appl_id}&status=Y`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.groupidd = res.data.data
            });
        },
        getEntity() {    
            axios.get(`${process.env.VUE_APP_URL}/api/master/entity?status=1`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.entities = res.data
            });
        },
        getEntity_id(){
               axios.get(`${process.env.VUE_APP_URL}/api/populateWaEventByAppl`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.entities = res.data
            });

            
        },
         serviceApplpwa({appl_id}){
          return appl_id
          },
          serviceEventpwa({event_id}){
          return event_id
          },
         serviceGroupwa({group_id}){
          return group_id
          },
        getWaAppl() {    
        axios.get(`${process.env.VUE_APP_URL}/api/master/aplikasi/getApplIDByEntity`, { 
            headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
        })
        .then(res => {
            this.groupmastersss = res.data.data
            // console.log(res)
           
        });
    },
     getEventid() {    
        axios.get(`${process.env.VUE_APP_URL}/api/populateWaEventByAppl`, { 
            headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
        })
        .then(res => {
            this.groupevent = res.data.data
             console.log(res)
           
        });
    },
     getGroupid() {    
        axios.get(`${process.env.VUE_APP_URL}/api/populateGroupByEntity`, { 
            headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
        })
        .then(res => {
            this.groupidd = res.data.data
             console.log(res)
           
        });
    },
        serviceApplication({appl_id}){
			return appl_id
		},
        serviceVariabelID({var_id}){
			return var_id
		},
        getApplication() {    
            axios.get(`${process.env.VUE_APP_URL}/api/master/aplikasi?status=Y`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.applications = res.data
            });
        },
        getEnvVariabel() {    
            this.loaderLoad();

            axios.get(`${process.env.VUE_APP_URL}/api/populateWaCenter`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            // axios.get(`${process.env.VUE_APP_URL}/api/populateWaEvent`, { 
            //     headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            // })
            .then(res => {
                this.loader.hide();
                this.env_vars = res.data.data;//menambah this.env_vars res.data.data
                //console.log(res);
                // console.log(this.env_vars);
                this.$nextTick(() => {
                    this.dataTableVar =     
                    $("#example").DataTable({
                                                "responsive": true,
                                                "autoWidth": false,
                                                "ordering": false,
                                                "info": true,
                                                "pageLength": 10,
                                                "bDestroy": true,
                                                "recordsFiltered":10,
                                                "destroy": true,
                                                "cache" : true,
                });
                });
            });
        },
        showModalCreate(){
            $("#createEnvVarModal").modal('show');
        },
        addEnvVariable(){
            $("#createEnvVarModal").modal('hide');
            this.loaderLoad()
           
            //console.log(" keluarlah " + this.entity_id + " || ayo " + this.event_id + " || iki apl " + this.appl_id + " || iki active " + this.active_flag)

        //     if (this.entity.entity_id == "" && this.appl_id == "" && this.event_id == "" && this.group_id == "" && this.active_flag == null)  {
        //         this.loader.hide();
        //         return swal("isien bro", {icon: "error", button: false})
                
        //     // }else if(this.entity.entity_id == ''){
        //     //     this.loader.hide();
        //     //     return swal("Entity juga di isi maseh", {icon: "error", button: false})
            
        //     }else if(this.appl_id == ''){
        //         this.loader.hide();
        //         return swal("Event ID Ndak Bole Kosong Mas e ", {icon: "error", button: false})
            
        //     }else if(this.event_id == ''){
        //         this.loader.hide();
        //         return swal("Event Name Ndak Bole Kosong Mas e", {icon: "error", button: false})
        
        //    }else if(this.group_id == ''){
        //         this.loader.hide();
        //         return swal("Message Name Ndak Bole Kosong Mas e", {icon: "error", button: false})
        //     }               
        //     // else if(this.created_at == ''){
        //     //     this.loader.hide();
        //     //     return swal(err.response.data.message, {icon: "hade", button: false})
        //     // }
        //     else if(this.active_flag == null){
        //         this.loader.hide();
        //         return swal("Active Flag e di isi yaa", {icon: "wokeh", button: false})
        //     } 

         if(this.entity.entity_id == undefined || this.entity.entity_id == '' || this.entity.entity_id == null)
            {
                this.loader.hide();
                swal("Entity Nya Di Isi Dulu ", {icon: "error", button: false});

                return;
            }

            if(this.applid.appl_id == undefined || this.applid.appl_id == '' || this.applid.appl_id == null)
            {
                this.loader.hide();
                swal("Appl ID Nya Di Isi Dulu ", {icon: "error", button: false});

                return;
            }

             if(this.eventid.event_id == undefined || this.eventid.event_id == '' || this.eventid.event_id == null)
            {
                this.loader.hide();
                swal("Event Nya Di Isi Dulu ", {icon: "error", button: false});

                return;
            }


            if(this.groupid.group_id == undefined || this.groupid.group_id == '' || this.groupid.group_id == null)
            {
                this.loader.hide();
                swal("Group ID Nya Di Isi Dulu ", {icon: "error", button: false});

                return;
            }

            if(this.active_flag == undefined || this.active_flag == '' || this.active_flag == null)
            {
                this.loader.hide();
                swal("Active Flag Nya Di Isi Dulu ", {icon: "error", button: false});

                return;
            }


         console.log(this.applid.appl_id);
        axios.post(`${process.env.VUE_APP_URL}/api/insertWaCenter`, {
                    'entity_id'  : this.entity.entity_id,
                    'appl_id'   : this.applid.appl_id,
                    'event_id'    : this.eventid.event_id,
                    'group_id'    : this.groupid.group_id,
                    'active_flag': this.active_flag
                 

                }, { 
                    headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                }).then(res => {
              
                    this.loader.hide();
                    $('#example').DataTable().destroy();
                    this.getEnvVariabel();
                    this.clear()
                    swal("Berhasil Menambahkan Wa Center Maseh", {icon: "success", button: false})
                }).catch(err => {
                    this.loader.hide();
                    swal(err.response.data.message, {icon: "error", button: false})
                    console.log(err.response);
                })
          
        },
        EditModal(data){
            // this.editEntity = data
            // this.editApplication = data
            this.editentity_id = data.entity_id
            this.editappl_id = data.appl_id
            this.editappl_id = data.event_id
             this.edigroup_id = data.group_id
            this.editactive_flag = data.active_flag
            $("#EditEntityModal").modal('show');
        },
        updateEnvVar(){
            $("#EditEntityModal").modal('hide');
            this.loaderLoad();
            axios.put(`${process.env.VUE_APP_URL}/api/updateWaCenter`, {
                
                'entity_id': this.editentity_id,
                'appl_id'  : this.editappl_id,
                'message' : this.editevent_id,
                'group_id' : this.editgroup_id,
                'active_flag' : this.editactive_flag

             
            }, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            }).then(res => {
                this.loader.hide();
                $('#example').DataTable().destroy();
                this.getEnvVariabel();
                swal("Berhasil Edit Wa Center Maseh", {icon: "success", button: false})
            }).catch(err => {
                this.loader.hide();
                swal(err.response.data.message, {icon: "error", button: false})
                console.log(err.response);
            })
        },
        deleteEnvVar(menu){
            if (menu.active_flag === 'Y') {
                this.updateStatus(menu, 'N')
                this.checked = false
            } else{
                this.updateStatus(menu, 'Y')
                this.checked = true
            }
        },
        updateStatus(menu, active_flag){
            axios.put(`${process.env.VUE_APP_URL}/api/updateWaCenter`, {
                'entity_id': menu.entity_id,
                'appl_id' : menu.appl_id,
                'event_id' : menu.event_id,
                'group_id' : menu.group_id,
                'active_flag': active_flag
            }, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            }).then(res => {
                console.log(active_flag)
                this.getEnvVariabel();
                $('#example').DataTable().destroy();
                this.dataTableVar.draw()
                swal("Berhasil update status Maseh", {icon: "success", button: false})
            }).catch(err => {
                swal(err.response.data.message, {icon: "error", button: false})
            })
        }
    },
    created() {
        this.getEnvVariabel()
    }
}
</script>
<style>

</style>