import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import store from "../store/index.js";
import DashboardLayout from '../layout/DashboardLayout.vue'
// GeneralViews
import NotFound from '../pages/NotFoundPage.vue'

// Admin pages
import Overview from '../pages/Overview.vue'
import UserProfile from '../pages/UserProfile.vue'

//Master
import Entity from '../pages/Master/Entity.vue'
import Application from '../pages/Master/Application.vue'
import Group from '../pages/Master/Group.vue'
import MenuHeader from '../pages/Master/MenuHeader.vue'
import MenuItem from '../pages/Master/MenuItem.vue'
import SecRight from '../pages/Master/SecRight.vue'
import EnvVariable from '../pages/Master/EnvVariable.vue'
import EnvVariableConf from '../pages/Master/EnvVariableConf.vue'
import WaEvent from '../pages/Master/WaEvent.vue'
import WaCenter from '../pages/Master/WaCenter.vue'
import WaGroup from '../pages/Master/WaGroup.vue'
Vue.use(VueRouter);

function checkAuth(to, from, resolve, reject) {
  if (store.getters.isUserLoggedIn || store.state.user.isLoggedIn) {
    resolve();
  } else {
    router.push({ path: '/' });
  }
}

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/home",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: '/admin',
    component: DashboardLayout,
    redirect: '/admin/overview',
    beforeEnter: checkAuth,
    children: [
      {
        path: 'overview',
        name: 'Overview',
        component: Overview
      },
      {
        path: 'user',
        name: 'User',
        component: UserProfile
      },
      {
        path: 'master/entity',
        name: 'Entity',
        component: Entity
      },
      {
        path: 'master/application',
        name: 'Application',
        component: Application
      },
      {
        path: 'master/group',
        name: 'Group',
        component: Group
      },
      {
        path: 'master/menuHeader',
        name: 'MenuHeader',
        component: MenuHeader
      },
      {
        path: 'master/menuItem',
        name: 'MenuItem',
        component: MenuItem
      },
      {
        path: 'master/group-maintenance',
        name: 'SecRight',
        component: SecRight
      },
      {
        path: 'master/env-variabel',
        name: 'EnvVariable',
        component: EnvVariable
      },
      {
        path: 'master/env-variabel-conf',
        name: 'EnvVariableConf',
        component: EnvVariableConf
      },
      {
        path: 'master/WaGroup',
        name: 'WaGroup',
        component: WaGroup
      },
      {
        path: 'master/waevent',
        name: 'WaEvent',
        component: WaEvent
      },
      {
        path: 'master/wacenter',
        name: 'WaCenter',
        component: WaCenter
      }
    ]
  },
  { path: '*', component: NotFound }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  linkExactActiveClass: "active",
  linkActiveClass: "active"
});

export default router;
