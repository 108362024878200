<template>
<div class="container">
			
			<div class="row justify-content-center">
				<div class="col-md-12 col-lg-10">
					<div class="wrap d-md-flex">
						<img src="img/Groupx.png"
                alt="login form" class="img-fluid" />
						<div class="login-wrap p-4 p-md-5">
			      	<div class="d-flex">
			      		<div class="w-100">
			      			<img src="img/logox.png"
                alt="login form" class="img2-fluid" />
			      		</div>
			      	</div>
			      		<div class="form-group mb-3">
			      			<label class="label" for="name">Username</label>
			      		<input id="username" name="username" type="text" class="form-control" placeholder="Username" autofocus required v-model="username">
			      		</div>
		            <div class="form-group mb-3">
		            	<label class="label" for="password">Password</label>
		              <input id="password" name="password" :type="passwordFieldType" class="form-control" placeholder="Password" required v-model="password" @keyup.enter="login()">
		            </div>
		            <div class="form-group">
		            	<button type="submit" class="form-control btn btn-primary rounded submit px-3" @click="login()">LOGIN</button>
		            </div>
		            <!-- <div class="form-group d-md-flex">
		            	<div class="w-50 text-left">
			            	<label class="checkbox-wrap checkbox-primary mb-0">Remember Me
									  <input type="checkbox" checked>
									  <span class="checkmark"></span>
										</label>
									</div>
									<div class="w-50 text-md-right">
										<a href="#">Forgot Password</a>
									</div>
		            </div> -->
		          <p class="text-center">Copyright © 2022 Viva Kencana, All rights reserved.</p>
		        </div>
		      </div>
				</div>
			</div>
		</div>
<!-- 
  <div class="form">
    <div class="form-container">
      <div class="form-form">
        <div class="form-form-wrap">
          <div class="form-container">
            <div class="form-content">
              <h1 class="">Sign In</h1>
              <p class="">Log in to your account to continue.</p>

              <form class="text-left">
                  <div class="form">

                      <div id="username-field" class="field-wrapper input">
                          <div class="d-flex justify-content-between">
                              <label for="username">USERNAME</label>
                          </div>
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                          <input id="username" name="username" type="text" class="form-control" placeholder="Username" autofocus required v-model="username">
                      </div>

                      <div id="password-field" class="field-wrapper input mb-2">
                          <div class="d-flex justify-content-between">
                              <label for="password">PASSWORD</label>
                          </div>
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-lock"><rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect><path d="M7 11V7a5 5 0 0 1 10 0v4"></path></svg>

                          <input id="password" name="password" :type="passwordFieldType" class="form-control" placeholder="Password" required v-model="password">
                          <svg style="display:none" @click="switchVisibility" id="display_eye" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path><circle cx="12" cy="12" r="3"></circle></svg>
                          <svg @click="switchVisibility" id="display_eye_off" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye-off"><path d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24"></path><line x1="1" y1="1" x2="23" y2="23"></line></svg>
                      </div>
                      <div class="d-sm-flex justify-content-between">
                          <div class="field-wrapper">
                              <button type="button" class="btn btn-primary" @click="login()">Login</button>
                          </div>
                      </div>

                  </div>
              </form>
              <snackbar ref="snackbar" baseSize="100px" :holdTime="5000" position="top-center"></snackbar>

            </div>                    
          </div>
        </div>
      </div>
    </div>
  </div> -->
</template>

<script>
require("@/assets/styles/form-2.css");
import Snackbar from "vuejs-snackbar";

export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
  components: {
		Snackbar
	},
  data() {
      return {
        username: '',
        password: '',
        eye: 0,
        passwordFieldType: 'password',
        isLoading: false,
        fullPage: true
      }
	},
	mounted(){
      document.querySelector("#app").style.backgroundImage = "url(img/background.png)";
  },
  methods: {
    switchVisibility() {
			if (this.eye == 0) {
        this.eye = 1
        this.passwordFieldType = 'text'
        document.querySelector("#display_eye").style.display = "block";
        document.querySelector("#display_eye_off").style.display = "none";
        
      } else{
        this.eye = 0
        this.passwordFieldType = 'password'
        document.querySelector("#display_eye").style.display = "none";
        document.querySelector("#display_eye_off").style.display = "block";
        
      }
		},
    login() {

        let username = this.username;
			  let password = this.password;

        if (password == '' && username == '') {
          return this.$refs.snackbar.error("Mohon diisi username dan password anda");
        } else if(username == ''){
          return this.$refs.snackbar.error("Mohon diisi username anda");
        } else if(password == ''){
          return this.$refs.snackbar.error("Mohon diisi password anda");
        } 

        let loader = this.$loading.show({
            // Optional parameters
            container: this.fullPage ? null : this.$refs.formContainer,
            color: '#0a58ca',
            loader: 'dots',
            width: 64,
            height: 64,
            backgroundColor: '#222223',
            opacity: 0.5,
            zIndex: 999,
        });

        this.$store
				.dispatch("loginUser", { username, password })
				.then(() => {
          loader.hide();
          this.$router.push('/admin');
				})
        .catch(err => {   
          loader.hide();
          return this.$refs.snackbar.error(err.response.data.message);
				});
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.container {
  padding-top: 162px;
 
}
.card {
    width: 913px;
    height: 492px;
    background-color: #6877FF;
    margin-left: 90px;
}

.img-fluid {
   
    margin-left: -7px;
    margin-bottom: -4px;
    margin-top: -2px;
    width: 644px;
    margin-right: -12px;
}
.img2-fluid {
    width: 240px;
    margin-bottom: 26px;
    padding-top: -42px;
    /* padding: 24px; */
    margin-left: -25px;
    margin-top: 52px;
}
.col {
    flex: 1 0 0%;
    background-color:#4455ee;
}
.p-md-5 {
    padding: 3rem !important;
    background-color: #4455ee;
}
.label {
    
  color: #ffffff;
}
.text-center {
    text-align: center !important;
    color: azure;
}

</style>
