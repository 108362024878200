<template>
  <div class="sidebar"
       :style="sidebarStyle"
       :data-color="backgroundColor"
       :data-image="backgroundImage">
    <div class="sidebar-wrapper">
      <div class="logo" style="border-bottom: 2px solid #f9fafb;">
        <a href="#" class="simple-text logo__container">
            <div class="logo-img" style="margin-bottom: 10px;">
            <div class="logo"> <img src="/user-management/img/logox.png" alt=""> </div>
            </div>
          {{title}}
        </a>
      </div>

      <slot name="content"></slot>
      <ul class="nav nav-main__links">
        <!--By default vue-router adds an active class to each route link. This way the links are colored when clicked-->
        <slot>
          <sidebar-link v-for="(link,index) in sidebarLinks"
                        :key="link.name + index"
                        :to="link.path"
                        @click="closeNavbar"
                        :link="link">
            <i :class="link.icon"></i>
            <p>{{link.name}}</p>
          </sidebar-link>
        </slot>
        <li to="/" tag="li" class="nav-item"  v-bind:class="{ active: isActive }" style="cursor:pointer">
            <a data-toggle="collapse" class="nav-link sidebar-menu-item" :aria-expanded="isActive ? 'true' : 'false'" @click="showOthers()">
              <i class="nc-icon nc-app"></i>
              <p>Master<b class="caret"></b>
              </p>
            </a>
            <div>
              <ul class="nav" style="" data-old-padding-top="" data-old-padding-bottom="" data-old-overflow="" v-bind:style="{ display }">
                <router-link tag="li" class="nav-item" :to="{path:'/admin/master/entity'}">
                  <a class="nav-link">
                    <span class="sidebar-mini">E</span>
                    <span class="sidebar-normal">Entity</span>
                  </a>
                </router-link>
                <router-link tag="li" class="nav-item" :to="{path:'/admin/master/application'}">
                  <a class="nav-link">
                    <span class="sidebar-mini">A</span>
                    <span class="sidebar-normal">Application</span>
                  </a>
                </router-link>
                <router-link tag="li" class="nav-item" :to="{path:'/admin/master/group'}">
                  <a class="nav-link">
                    <span class="sidebar-mini">GM</span>
                    <span class="sidebar-normal">Group Master</span>
                  </a>
                </router-link>
                <router-link tag="li" class="nav-item" :to="{path:'/admin/master/menuHeader'}">
                  <a class="nav-link">
                    <span class="sidebar-mini">MD</span>
                    <span class="sidebar-normal">Menu Header</span>
                  </a>
                </router-link>
                <router-link tag="li" class="nav-item" :to="{path:'/admin/master/menuItem'}">
                  <a class="nav-link">
                    <span class="sidebar-mini">MI</span>
                    <span class="sidebar-normal">Menu Item</span>
                  </a>
                </router-link>
                
                <router-link tag="li" class="nav-item" :to="{path:'/admin/master/group-maintenance'}">
                  <a class="nav-link">
                    <span class="sidebar-mini">GR</span>
                    <span class="sidebar-normal">Group Right</span>
                  </a>
                </router-link>

                <router-link tag="li" class="nav-item" :to="{path:'/admin/master/env-variabel'}">
                  <a class="nav-link">
                    <span class="sidebar-mini">EV</span>
                    <span class="sidebar-normal">Env Variabel</span>
                  </a>
                </router-link>
                <router-link tag="li" class="nav-item" :to="{path:'/admin/master/env-variabel-conf'}">
                  <a class="nav-link">
                    <span class="sidebar-mini">EC</span>
                    <span class="sidebar-normal">Env Configuration</span>
                  </a>
                </router-link>
              </ul>
            </div>
        </li>
        <li to="/" tag="li" class="nav-item"  v-bind:class="{ active: isActiveWa }" style="cursor:pointer">
           <a data-toggle="collapse" class="nav-link sidebar-menu-item" :aria-expanded="isActiveWa ? 'true' : 'false'" @click="showOthersss()">
             <div class="oke">
            <svg xmlns="http://www.w3.org/2000/svg" width="23" height="35" fill="currentColor" class="bi bi-whatsapp" viewBox="0 0 16 16">
            <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z"/>
           </svg>
             </div>
            
             <p>Wa Management<b class="caret"></b>
              </p>
            </a>
              <div>
               <ul class="nav" style="" data-old-padding-top="" data-old-padding-bottom="" data-old-overflow="" v-show="displayWa === 'block'">
                <router-link tag="li" class="nav-item" :to="{path:'/admin/master/wacenter'}">
                  <a class="nav-link">
                    <span class="sidebar-mini">Wa</span>
                    <span class="sidebar-normal">Wa Center</span>
                  </a>
                </router-link>
                <router-link tag="li" class="nav-item" :to="{path:'/admin/master/waevent'}">
                  <a class="nav-link">
                    <span class="sidebar-mini">Wa</span>
                    <span class="sidebar-normal">Wa Event</span>
                  </a>
                </router-link>
                <router-link tag="li" class="nav-item" :to="{path:'/admin/master/wagroup'}">
                  <a class="nav-link">
                    <span class="sidebar-mini">Wa</span>
                    <span class="sidebar-normal">Wa Group</span>
                  </a>
                </router-link>
              </ul>
            </div>  
         </li>
      </ul>
      <ul class="nav nav-bottom" v-if="$slots['bottom-links']">
        <slot name="bottom-links"></slot>
      </ul>
    </div>
  </div>
  <!-- <router-link tag="li" class="nav-item" :to="{path:'/admin/master/wamanagement'}"> -->
</template>
<script>
  import SidebarLink from './SidebarLink.vue'
  export default {
    components: {
      SidebarLink
      
    },
    data(){
      return {
        display: 'none',
        isActive: false,
        displayWa: 'none',
        isActiveWa: false
      }
    },
    props: {
      title: {
        type: String,
        default: ''
      },
      backgroundColor: {
        type: String,
        default: 'blue',
        validator: (value) => {
          let acceptedValues = ['', 'blue', 'azure', 'green', 'orange', 'red', 'purple', 'black']
          return acceptedValues.indexOf(value) !== -1
        }
      },
      backgroundImage: {
        type: String,
        default: 'img/sidebar-5.jpg'
      },
      activeColor: {
        type: String,
        default: 'success',
        validator: (value) => {
          let acceptedValues = ['primary', 'info', 'success', 'warning', 'danger']
          return acceptedValues.indexOf(value) !== -1
        }
      },
      sidebarLinks: {
        type: Array,
        default: () => []
      },
      autoClose: {
        type: Boolean,
        default: true
      }
    },
    provide () {
      return {
        autoClose: this.autoClose
      }
    },
    computed: {
      sidebarStyle () {
        return {
          backgroundImage: `url(${this.backgroundImage})`
        }
      }
    },
     methods: {
      showOthers: function() {
        if(this.display == "none"){
          this.display = 'block';
          this.isActive = true
        } else{
          this.display = 'none';
          this.isActive = false
        }
      },
      showOthersss: function() {
            console.log(this.displayWa);

          if(this.displayWa == "none"){
            this.displayWa = 'block';
            this.isActiveWa = true
          } else{
            this.displayWa = 'none';
            this.isActiveWa = false
          }
      }
     }
  }

</script>
<style>

  .sidebar .sidebar-wrapper {
    display: flex;
    flex-direction: column;
  }
 .sidebar .nav-main__links {
   flex: 1;
 }
 
 .sidebar .sidebar-wrapper .logo .logo__container {
   padding-left: 46px;
  
 }
 .oke {
   margin-left:-170px;
   margin-bottom: -31px;

 }
 

</style>
