import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import BootstrapVue from "bootstrap-vue/dist/bootstrap-vue.esm";
import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
// LightBootstrap plugin
import LightBootstrap from './light-bootstrap-main';
import './registerServiceWorker';
import VueSidebarMenu from 'vue-sidebar-menu';
import 'vue-sidebar-menu/dist/vue-sidebar-menu.css';

import { VuejsDatatableFactory } from 'vuejs-datatable';
 
Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(VueLoading);
Vue.use(LightBootstrap);
Vue.use(VueSidebarMenu);
Vue.use(VuejsDatatableFactory);

require("@/assets/styles/styles.css");
require("dotenv").config();
window.axios = require('axios').default;
// axios.defaults.baseURL = process.env.BASE_URL;

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

window.$ = window.jQuery = require('jquery');
require('bootstrap');

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

