<template>
  <div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-12">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><router-link :to="{path:'/admin/'}">Dashboard</router-link></li>
                    <li class="breadcrumb-item active" aria-current="page">User Management</li>
                </ol>
            </nav>
        </div>
        <div class="col-md-6">
          <div class="card">
            <div class="row card-body"> 
              <div class="col-lg-10"> 
                <h5>User Maintenance</h5>
              </div>
              <div class="col-lg-2">
                  <button class="btn btn-success btn-sm" style="float: right;" @click="showModalUserCreate()">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-plus-circle"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="16"></line><line x1="8" y1="12" x2="16" y2="12"></line></svg>
                  </button>            
              </div>
              <div class="col-lg-12 mt-3">
                <table class="table table-striped table-sm table-bordered table-hover display nowrap" id="example" style="width:100%">
                    <thead>
                        <tr>
                            <th style="text-align:center !important">User ID</th>
                            <th style="text-align:center !important">Name</th>
                            <th style="text-align:center !important">Phone</th>
                            <th style="text-align:center !important">Plant</th>
                            <th style="text-align:center !important">Division</th>
                            <th style="text-align:center !important">Department</th>
                            <!-- <th style="text-align:center !important">Wa Group ID</th> -->
                            <th style="text-align:center !important">Status</th>
                            <th style="text-align:center !important">Action</th>
                            
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="user in users" :key="user.id">
                            <td>{{user.user_id2}}</td>
                            <td>{{user.name1}}</td>
                            <td>{{user.phone}}</td>
                            <td>{{user.plant}}</td>
                            <td>{{user.division}}</td>
                            <td>{{user.dept}}</td>
                            <!-- <td>{{user.wa_group_id}}</td> -->
                          
                            
                            <td>
                                <label class="switch" v-if="user.active_flag === 'N'">
                                    <input type="checkbox" v-on:change="deleteUserItem(user)">
                                    <span class="slider round"></span>
                                </label>
                                <label class="switch" v-else>
                                    <input type="checkbox" v-on:change="deleteUserItem(user)" checked>
                                    <span class="slider round"></span>
                                </label>
                            </td>
                            <td>
                                <button class="btn btn-sm btn-warning" @click="EditModal(user)">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit-2"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg>
                                </button>
                                <!-- <button class="btn btn-sm btn-danger" style="margin-left:5px" @click="deleteUserItem(user)">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg>
                                </button> -->
                            </td>
                        </tr>
                    </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card">
            <div class="row card-body"> 
              <div class="col-lg-10"> 
                <h5>User Group Maintenance</h5>
              </div>
              <div class="col-lg-2">
                  <button class="btn btn-success btn-sm" style="float: right;" @click="showModalUserGroupCreate()">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-plus-circle"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="16"></line><line x1="8" y1="12" x2="16" y2="12"></line></svg>
                  </button>            
              </div>
              <div class="col-lg-12 mt-3">
                <table class="table table-striped table-sm table-bordered table-hover display nowrap" style="width:100%" id="example_group">
                    <thead>
                        <tr>
                            <th style="text-align:center !important">Application ID</th>
                            <th style="text-align:center !important">Entity</th>
                            <th style="text-align:center !important">User ID</th>
                            <th style="text-align:center !important">Group ID</th>
                            <th style="text-align:center !important">Status</th>
                            <th style="text-align:center !important">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="group in groups" :key="group.id">
                            <td>{{group.appl_id}}</td>
                            <td>{{group.entity_id}}</td>
                            <td>{{group.user_id2}}</td>
                            <td>{{group.group_id}}</td>
                            <td>
                                <label class="switch" v-if="group.active_flag === 'N'">
                                    <input type="checkbox" v-on:change="deleteUserGroupItem(group)">
                                    <span class="slider round"></span>
                                </label>
                                <label class="switch" v-else>
                                    <input type="checkbox" v-on:change="deleteUserGroupItem(group)" checked>
                                    <span class="slider round"></span>
                                </label>
                            </td>
                            <td>
                                <button class="btn btn-sm btn-warning" @click="EditModalGroup(group)">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit-2"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg>
                                </button>
                                <!-- <button class="btn btn-sm btn-danger" style="margin-left:5px" @click="deleteUserGroupItem(group)">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg>
                                </button> -->
                            </td>
                        </tr>
                    </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="modal fade" id="createUserModal" tabindex="-1" aria-labelledby="createUserModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                <div class="modal-content">
                  <div class="modal-header">
                      <h5 class="modal-title" id="createUserModalLabel">Tambah User</h5>
                      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div class="modal-body py-lg-10 px-lg-10">
                    <div class="row g-3 align-items-center">
                        <div class="col-md-3">
                            <label for="username" class="col-form-label">Username</label>
                        </div>
                        <div class="col-md-3">
                            <input type="text" id="username" class="form-control" aria-describedby="usernameHelpInline" v-model="username">
                        </div>
                        <div class="col-md-3">
                            <label for="password" class="col-form-label">Password</label>
                        </div>
                        <div class="col-md-3">
                            <input type="password" id="password" class="form-control" aria-describedby="passwordHelpInline" v-model="password">
                        </div>
                    </div>
                    <div class="row g-3 align-items-center mt-1">
                        <div class="col-md-3">
                            <label for="name1" class="col-form-label">Name 1</label>
                        </div>
                        <div class="col-md-3">
                            <input type="text" id="name1" class="form-control" aria-describedby="name1HelpInline" v-model="name1">
                        </div>
                        <div class="col-md-3">
                            <label for="name2" class="col-form-label">Name 2</label>
                        </div>
                        <div class="col-md-3">
                            <input type="text" id="name2" class="form-control" aria-describedby="name2HelpInline" v-model="name2">
                        </div>
                    </div>
                    <div class="row g-3 align-items-center mt-1">
                        <div class="col-md-3">
                            <label for="name3" class="col-form-label">Name 3</label>
                        </div>
                        <div class="col-md-3">
                            <input type="text" id="name3" class="form-control" aria-describedby="name3HelpInline" v-model="name3">
                        </div>
                        <div class="col-md-3">
                            <label for="plant" class="col-form-label">Plant</label>
                        </div>
                        <div class="col-md-3">
                            <input type="text" id="plant" class="form-control" aria-describedby="plantHelpInline" v-model="plant">
                        </div>
                    </div>
                    <div class="row g-3 align-items-center mt-1">
                        <div class="col-md-3">
                            <label for="division" class="col-form-label">Division</label>
                        </div>
                        <div class="col-md-3">
                            <input type="text" id="division" class="form-control" aria-describedby="divisionHelpInline" v-model="division">
                        </div>
                        <div class="col-md-3">
                            <label for="dept" class="col-form-label">Department</label>
                        </div>
                        <div class="col-md-3">
                            <input type="text" id="dept" class="form-control" aria-describedby="deptHelpInline" v-model="dept">
                        </div>
                    </div>
                    <div class="row g-3 align-items-center mt-1">
                        <div class="col-md-3">
                            <label for="section" class="col-form-label">Section</label>
                        </div>
                        <div class="col-md-3">
                            <input type="text" id="section" class="form-control" aria-describedby="sectionHelpInline" v-model="section">
                        </div>
                        <div class="col-md-3">
                            <label for="phone" class="col-form-label">No Telepon</label>
                        </div>
                        <div class="col-md-3">
                            <input type="text" id="phone" class="form-control" aria-describedby="phoneHelpInline" v-model="phone">
                        </div>
                        
                        <div class="col-md-3">
                            <label for="status" class="col-form-label">Status</label>
                        </div>
                        <div class="col-md-3">
                            <select class="form-select" aria-label="Select" v-model="status">
                                <option value="Y">Aktif</option>
                                <option value="N">Tidak Aktif</option>
                            </select>
                        </div>
                        <!-- <div class="col-md-3">
                            <label for="group_id" class="col-form-label">Wa Group Id</label>
                        </div>
                        <div class="col-md-3">
                            <multiselect 
                                v-model="wagroup_id" 
                                :custom-label="serviceGroupwa" 
                                :options="groupmastersss" 
                                placeholder="Select One" 
                                label="group_id" 
                                track-by="group_id" 
                                :allow-empty="false" 
                                :showLabels="false" 
                                name="group_id" 
                                autofocuc="yes"
                                class="form-control"
                            ></multiselect>
                        </div> -->
                        
                    </div>  
                  </div>
                  <div class="modal-footer">
                      <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                      <button type="button" class="btn btn-success" @click="addUserSave()">Save</button>
                  </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="EditUser" tabindex="-1" aria-labelledby="EditUserLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                <div class="modal-content">
                  <div class="modal-header">
                      <h5 class="modal-title" id="EditUserLabel">Edit User</h5>
                      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div class="modal-body py-lg-10 px-lg-10">
                    <div class="row g-3 align-items-center">
                        <div class="col-md-3">
                            <label for="editUsername" class="col-form-label">Username</label>
                        </div>
                        <div class="col-md-3">
                            <input type="text" id="editUsername" class="form-control" aria-describedby="editUsernameHelpInline" v-model="editUsername" disabled>
                        </div>
                        <div class="col-md-3">
                            <label for="editPassword" class="col-form-label">Password</label>
                        </div>
                        <div class="col-md-3">
                            <input type="password" id="editPassword" class="form-control" aria-describedby="editPasswordHelpInline" v-model="editPassword">
                        </div>
                    </div>
                    <div class="row g-3 align-items-center mt-1">
                        <div class="col-md-3">
                            <label for="editName1" class="col-form-label">Name 1</label>
                        </div>
                        <div class="col-md-3">
                            <input type="text" id="editName1" class="form-control" aria-describedby="editName1HelpInline" v-model="editName1">
                        </div>
                        <div class="col-md-3">
                            <label for="editName2" class="col-form-label">Name 2</label>
                        </div>
                        <div class="col-md-3">
                            <input type="text" id="editName2" class="form-control" aria-describedby="editName2HelpInline" v-model="editName2">
                        </div>
                    </div>
                    <div class="row g-3 align-items-center mt-1">
                        <div class="col-md-3">
                            <label for="editName3" class="col-form-label">Name 3</label>
                        </div>
                        <div class="col-md-3">
                            <input type="text" id="editName3" class="form-control" aria-describedby="editName3HelpInline" v-model="editName3">
                        </div>
                        <div class="col-md-3">
                            <label for="editPlant" class="col-form-label">Plant</label>
                        </div>
                        <div class="col-md-3">
                            <input type="text" id="editPlant" class="form-control" aria-describedby="editPlantHelpInline" v-model="editPlant">
                        </div>
                    </div>
                    <div class="row g-3 align-items-center mt-1">
                        <div class="col-md-3">
                            <label for="editDivision" class="col-form-label">Division</label>
                        </div>
                        <div class="col-md-3">
                            <input type="text" id="editDivision" class="form-control" aria-describedby="editDivisionHelpInline" v-model="editDivision">
                        </div>
                        <div class="col-md-3">
                            <label for="editDept" class="col-form-label">Department</label>
                        </div>
                        <div class="col-md-3">
                            <input type="text" id="editDept" class="form-control" aria-describedby="editDeptHelpInline" v-model="editDept">
                        </div>
                    </div>
                    <div class="row g-3 align-items-center mt-1">
                        <div class="col-md-3">
                            <label for="editSection" class="col-form-label">Section</label>
                        </div>
                        <div class="col-md-3">
                            <input type="text" id="editSection" class="form-control" aria-describedby="editSectionHelpInline" v-model="editSection">
                        </div>
                         <div class="col-md-3">
                            <label for="section" class="col-form-label">No Telepon</label>
                        </div>
                        <div class="col-md-3">
                            <input type="text" id="phone" class="form-control" aria-describedby="phoneHelpInline" v-model="phone">
                        </div>
                         <!-- <div class="col-md-3">
                            <label for="section" class="col-form-label">Wa Group ID</label>
                        </div>
                       <div class="col-md-3">
                            <multiselect 
                                v-model="wagroup_id" 
                                :custom-label="serviceGroupwa" 
                                :options="groupmastersss" 
                                placeholder="Select One" 
                                label="group_id" 
                                track-by="group_id" 
                                :allow-empty="false" 
                                :showLabels="false" 
                                name="group_id" 
                                autofocuc="yes"
                                class="form-control"
                            ></multiselect>
                        </div> -->
                        <div class="col-md-3">
                            <label for="status" class="col-form-label">Status</label>
                        </div>
                        <div class="col-md-3">
                            <select class="form-select" aria-label="Select" v-model="editStatus">
                                <option value="Y">Aktif</option>
                                <option value="N">Tidak Aktif</option>
                            </select>
                        </div>
                    </div>
                  </div>
                  <div class="modal-footer">
                      <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                      <button type="button" class="btn btn-success" @click="updateUserSave()">Save</button>
                  </div>
                </div>
            </div>
        </div>

        <!-- User Group -->
        <div class="modal fade" id="createUserGroupModal" tabindex="-1" aria-labelledby="createUserGroupModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-scrollable">
                <div class="modal-content">
                  <div class="modal-header">
                      <h5 class="modal-title" id="createUserGroupModalLabel">Tambah User Group</h5>
                      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div class="modal-body py-lg-10 px-lg-10">
                    <div class="row g-3 align-items-center">
                        <div class="col-md-3">
                            <label for="entity" class="col-form-label">Entity</label>
                        </div>
                        <div class="col-auto">
                            <multiselect 
                                v-model="entity" 
                                :custom-label="serviceEntity" 
                                :options="entities" 
                                placeholder="Select One" 
                                label="description" 
                                track-by="description" 
                                :allow-empty="false" 
                                :showLabels="false" 
                                name="entity_id" 
                                autofocuc="yes"
                                class="form-control"
                                @input="setEntity()"
                            ></multiselect>
                        </div>
                    </div>
                    <div class="row g-3 align-items-center mt-1">
                        <div class="col-md-3">
                            <label for="entity" class="col-form-label">Application</label>
                        </div>
                        <div class="col-auto">
                            <multiselect 
                                v-model="application" 
                                :custom-label="serviceApplication" 
                                :options="applications" 
                                placeholder="Select One" 
                                label="appl_id" 
                                track-by="appl_id" 
                                :allow-empty="false" 
                                :showLabels="false" 
                                name="appl_id" 
                                autofocuc="yes"
                                class="form-control"
                            ></multiselect>
                        </div>
                    </div>
                    <div class="row g-3 align-items-center mt-1">
                        <div class="col-md-3">
                            <label for="entity" class="col-form-label">Group</label>
                        </div>
                        <div class="col-auto">
                            <multiselect 
                                v-model="group" 
                                :custom-label="serviceGroupMaster" 
                                :options="groupmasterss" 
                                placeholder="Select One" 
                                label="group_id" 
                                track-by="group_id" 
                                :allow-empty="false" 
                                :showLabels="false" 
                                name="group_id" 
                                autofocuc="yes"
                                class="form-control"
                            ></multiselect>
                        </div>
                    </div>
                    <div class="row g-3 align-items-center mt-1">
                        <div class="col-md-3">
                            <label for="entity" class="col-form-label">User</label>
                        </div>
                        <div class="col-auto">
                            <multiselect 
                                v-model="user" 
                                :custom-label="serviceUser" 
                                :options="users" 
                                placeholder="Select One" 
                                label="user_num" 
                                track-by="user_num" 
                                :allow-empty="false" 
                                :showLabels="false" 
                                name="user_num" 
                                autofocuc="yes"
                                class="form-control"
                            ></multiselect>
                        </div>
                    </div>
                    <div class="row g-3 align-items-center mt-1">
                        <div class="col-md-3">
                            <label for="note_group" class="col-form-label">Note</label>
                        </div>
                        <div class="col-auto">
                            <input type="text" id="note_group" class="form-control" aria-describedby="note_groupHelpInline" v-model="note_group">
                        </div>
                    </div>
                    <div class="row g-3 align-items-center mt-1">
                        <div class="col-md-3">
                            <label for="status" class="col-form-label">Status</label>
                        </div>
                        <div class="col-auto">
                            <select class="form-select" aria-label="Select" v-model="statusGroup">
                                <option value="Y">Aktif</option>
                                <option value="N">Tidak Aktif</option>
                            </select>
                        </div>
                    </div>
                  </div>
                  <div class="modal-footer">
                      <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                      <button type="button" class="btn btn-success" @click="addUserGroupSave()">Save</button>
                  </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="editUserGroupModal" tabindex="-1" aria-labelledby="editUserGroupModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-scrollable">
                <div class="modal-content">
                  <div class="modal-header">
                      <h5 class="modal-title" id="editUserGroupModalLabel">Update User Group</h5>
                      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div class="modal-body py-lg-10 px-lg-10">
                    <div class="row g-3 align-items-center">
                        <div class="col-md-3">
                            <label for="entity" class="col-form-label">Entity</label>
                        </div>
                        <div class="col-auto">
                            <multiselect 
                                v-model="editEntity" 
                                :custom-label="serviceEntity" 
                                :options="entities" 
                                placeholder="Select One" 
                                label="description" 
                                track-by="description" 
                                :allow-empty="false" 
                                :showLabels="false" 
                                name="entity_id" 
                                autofocuc="yes"
                                class="form-control"
                                disabled
                            ></multiselect>
                        </div>
                    </div>
                    <div class="row g-3 align-items-center mt-1">
                        <div class="col-md-3">
                            <label for="entity" class="col-form-label">Application</label>
                        </div>
                        <div class="col-auto">
                            <multiselect 
                                v-model="editApplication" 
                                :custom-label="serviceApplication" 
                                :options="applications" 
                                placeholder="Select One" 
                                label="appl_id" 
                                track-by="appl_id" 
                                :allow-empty="false" 
                                :showLabels="false" 
                                name="appl_id" 
                                autofocuc="yes"
                                class="form-control"
                                disabled
                            ></multiselect>
                        </div>
                    </div>
                    <div class="row g-3 align-items-center mt-1">
                        <div class="col-md-3">
                            <label for="entity" class="col-form-label">Group</label>
                        </div>
                        <div class="col-auto">
                            <multiselect 
                                v-model="editGroup" 
                                :custom-label="serviceGroupMaster" 
                                :options="groupmasterss" 
                                placeholder="Select One" 
                                label="group_id" 
                                track-by="group_id" 
                                :allow-empty="false" 
                                :showLabels="false" 
                                name="group_id" 
                                autofocuc="yes"
                                class="form-control"
                                disabled
                            ></multiselect>
                        </div>
                    </div>
                    <div class="row g-3 align-items-center mt-1">
                        <div class="col-md-3">
                            <label for="entity" class="col-form-label">User</label>
                        </div>
                        <div class="col-auto">
                            <multiselect 
                                v-model="editUser" 
                                :custom-label="serviceUser" 
                                :options="users" 
                                placeholder="Select One" 
                                label="user_id2" 
                                track-by="user_id2" 
                                :allow-empty="false" 
                                :showLabels="false" 
                                name="user_id2" 
                                autofocuc="yes"
                                class="form-control"
                                disabled
                            ></multiselect>
                        </div>
                    </div>
                    <div class="row g-3 align-items-center mt-1">
                        <div class="col-md-3">
                            <label for="editNote_group" class="col-form-label">Note</label>
                        </div>
                        <div class="col-auto">
                            <input type="text" id="editNote_group" class="form-control" aria-describedby="editNote_groupHelpInline" v-model="editNote_group">
                        </div>
                    </div>
                    <div class="row g-3 align-items-center mt-1">
                        <div class="col-md-3">
                            <label for="status" class="col-form-label">Status</label>
                        </div>
                        <div class="col-auto">
                            <select class="form-select" aria-label="Select" v-model="editStatusGroup">
                                <option value="Y">Aktif</option>
                                <option value="N">Tidak Aktif</option>
                            </select>
                        </div>
                    </div>
                  </div>
                  <div class="modal-footer">
                      <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                      <button type="button" class="btn btn-success" @click="updateUserGroupSave()">Save</button>
                  </div>
                </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<script>
//Bootstrap and jQuery libraries
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery'; 
import Snackbar from "vuejs-snackbar";
import swal from 'sweetalert';
import Multiselect from 'vue-multiselect'


export default {
  mounted() {
    this.getEntity()
    this.getApplication()
    this.getGroupMaster()
    this.getWagroup()
  },
  data(){
    return {
      menus: [],
      fullPage: true, 
      loader : null,
      users: [],
      groups: [],
      username: '',
      password: '',
      name1: '',
      name2: '',
      name3: '',
      plant: '',
      division: '',
      dept: '',
      phone:'',
      group_id:'',
      wagroup_id:'',
      wa_group_id:'',
      section: '',
      editUsername: '',
      editPassword: '',
      editName1: '',
      editName2: '',
      editName3: '',
      editPlant: '',
      editDivision: '',
      editDept: '',
      editSection: '',
      status: null,
      editStatus: '',
      editStatusGroup: '',
      statusGroup: null,
      dataUser: null,
      entities: [],
      entity: {},
      applications: [],
      application: {},
      groupmasterss: [],
      groupmastersss: [],
      group: {},
      user: {},
      note_group: '',
      editEntity: {},
      editApplication: {},
      editGroup: {},
      editUser: {},
      editNote_group: '',
      dataGroupUser: null
    }
  },
  components: {
    Snackbar, Multiselect
  },  
  methods:{
    clear(){
        this.username = ''
        this.password = ''
        this.name1 = ''
        this.name2 = ''
        this.name3 = ''
        this.plant = ''
        this.division = ''
        this.dept = ''
        this.section = ''
        this.phone =''
        this.group = ''
        this.wagroup_id =''
        this.group_id =''
        this.wa_group_id =''

    },
    clearGroup(){
        this.entity = ''
        this.application = ''
        this.group = ''
        this.user = ''
        this.wagroup_id =''
        this.note_group = ''
    },
    loaderLoad(){
      this.loader = this.$loading.show({
          // Optional parameters
          container: this.fullPage ? null : this.$refs.formContainer,
          color: '#0a58ca',
          loader: 'dots',
          width: 64,
          height: 64,
          backgroundColor: '#222223',
          opacity: 0.5,
          zIndex: 999,
      });
    },
    EditModal(user){
      this.dataUser = user
      this.editUsername = user.username
      this.editName1 = user.name1
      this.editName2 = user.name2
      this.editName3 = user.name3
      this.editPlant = user.plant
      this.editDivision = user.division
      this.editPhone = user.phone
      this.editGroup_id = user.group_id
      this.editWa_Grop_id = user.wa_group_id
      this.editDept = user.dept
      this.editSection = user.section
      this.editStatus = user.active_flag

      $("#EditUser").modal('show');

    },
    deleteUserItem(user){
        if (user.active_flag === 'Y') {
            this.updateStatus(user, 'N')
        } else{
            this.updateStatus(user, 'Y')
        }
    },
    updateStatus(user, active_flag){
        axios.post(`${process.env.VUE_APP_URL}/api/user/delete`, {
            '_method': 'DELETE',
            'user_num' : user.user_num,
            'active_flag' : active_flag
        }, { 
            headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
        }).then(res => {
            $('#example').DataTable().destroy();
            this.getUser();
            swal("Berhasil update status", {icon: "success", button: false})
        }).catch(err => {
            swal(err.response.data.message, {icon: "error", button: false})
        })
    },
    EditModalGroup(group){
        this.dataGroupUser = group
        this.editEntity = group
        this.editApplication = group
        this.editGroup = group
        this.editUser = group
        this.editNote_group = group.note1
        this.editStatusGroup = group.active_flag
        
        $("#editUserGroupModal").modal('show');

    },
    deleteUserGroupItem(group){
        if (group.active_flag === 'Y') {
            this.updateStatusUserGroup(group, 'N')
        } else {
            this.updateStatusUserGroup(group, 'Y')
        }
    },
    updateStatusUserGroup(group, status){
        axios.post(`${process.env.VUE_APP_URL}/api/group/delete`, {
            '_method': 'DELETE',
            'entity_id' : group.entity_id,
            'appl_id' : group.appl_id,
            'user_id2' : group.user_id2,
            'group_id' : group.group_id,
            'status' : status
        }, { 
            headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
        }).then(res => {
            $('#example_group').DataTable().destroy();
            this.getUserGroup();
            swal("Berhasil nonaktifkan", {icon: "success", button: false})
        }).catch(err => {
            swal(err.response.data.message, {icon: "error"})
        })
    },
    showModalUserCreate(){
        $("#createUserModal").modal('show');
    },
    showModalUserGroupCreate(){
        $("#createUserGroupModal").modal('show');
    },
    getUser() {    
        this.loaderLoad();

        axios.get(`${process.env.VUE_APP_URL}/api/user`, { 
            headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
        })
        .then(res => {
            this.loader.hide();
            this.users = res.data
            this.$nextTick(() => {
                $("#example").DataTable({
                    "responsive": true,
                    "autoWidth": false,
                    "ordering": false,
                    "info": true,
                    "pageLength": 10,
                    "bDestroy": true,
                    "recordsFiltered":10,
                    "destroy": true,
                });
            });
        });
    },
    addUserSave(){
      $("#createUserModal").modal('hide');
      this.loaderLoad();
      console.log(this.wagroup_id);

      axios.post(`${process.env.VUE_APP_URL}/api/user`, {
          'username': this.username,
          'password': this.password,
          'name1': this.name1,
          'name2' : this.name2,
          'name3' : this.name3,
          'plant' : this.plant,
          'division' : this.division,
          'dept' : this.dept,
          'section' : this.section,
          'status' : this.status,
          'phone' :this.phone,//
        //   'group_id' :this.group_id,
        //   'wa_group_id':this.wagroup_id.group_id//
      }, { 
          headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
      }).then(res => {
            this.loader.hide();
            $('#example').DataTable().destroy();
            this.getUser();
            swal("Berhasil tambah User", {icon: "success", button: false})
            this.clear()
      }).catch(err => {
          console.log(err.response.data.message);
          swal(err.response.data.message, {icon: "error"})
          this.loader.hide();
      })
    },
    updateUserSave(){
      $("#EditUser").modal('hide');
      this.loaderLoad();

      axios.put(`${process.env.VUE_APP_URL}/api/user/update`, {
          'username': this.editUsername,
          'password': this.editPassword ? this.editPassword : '',
          'name1': this.editName1,
          'name2' : this.editName2,
          'name3' : this.editName3,
          'plant' : this.editPlant,
          'division' : this.editPlant,
          'dept' : this.editDept,
          'section' : this.editSection,
          'user_num' : this.dataUser.user_num,
          'status' : this.editStatus,
          'phone' :this.phone,//
        //   'group_id' :this.group_id,
          //'wa_group_id':this.wagroup_id.wa_group_id//
      }, { 
          headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
      }).then(res => {
            this.loader.hide();
            $('#example').DataTable().destroy();
            this.getUser();
            swal("Berhasil update User", {icon: "success", button: false})

      }).catch(err => {
          console.log(err.response.data.message);
          swal(err.response.data.message, {icon: "error"})
          this.loader.hide();
      })
    },
    getUserGroup() {    
        // this.loaderLoad();

        axios.get(`${process.env.VUE_APP_URL}/api/group`, { 
            headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
        })
        .then(res => {
            // this.loader.hide();
            this.groups = res.data
            this.$nextTick(() => {
                $("#example_group").DataTable({
                    "responsive": true,
                    "autoWidth": false,
                    "ordering": false,
                    "info": true,
                    "pageLength": 10,
                    "bDestroy": true,
                    "recordsFiltered":10,
                    "destroy": true,
                });
            });
        });
    },
    serviceEntity ({entity_id}) {
        return entity_id
    },
    setEntity(){
        axios.get(`${process.env.VUE_APP_URL}/api/master/aplikasi?entity_id=${this.entity.entity_id}&status=Y`, { 
            headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
        })
        .then(res => {
            this.applications = res.data
            this.editApplication = res.data
        });
    },
    getEntity() {    
        axios.get(`${process.env.VUE_APP_URL}/api/master/entity?status=1`, { 
            headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
        })
        .then(res => {
            this.entities = res.data
        });
    },
    serviceApplication({appl_id}){
      return appl_id
    },
    getApplication() {    
        axios.get(`${process.env.VUE_APP_URL}/api/master/aplikasi?status=Y`, { 
            headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
        })
        .then(res => {
            this.applications = res.data
        });
    },
    serviceUser({user_id2}){
      return user_id2
    },
    serviceGroupMaster({group_id}){
      return group_id
    },
    serviceGroupwa({group_id}){
      return group_id
    },
    getGroupMaster() {    
        axios.get(`${process.env.VUE_APP_URL}/api/master/group_master?status=1`, { 
            headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
        })
        .then(res => {
            this.groupmasterss = res.data
        });
    },
     getWagroup() {    
        axios.get(`${process.env.VUE_APP_URL}/api/populateWaGroup`, { 
            headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
        })
        .then(res => {
            this.groupmastersss = res.data.data
           
        });
    },
    addUserGroupSave(){
      $("#createUserGroupModal").modal('hide');
      this.loaderLoad();

      axios.post(`${process.env.VUE_APP_URL}/api/group`, {
          'entity_id': this.entity.entity_id,
          'appl_id': this.application.appl_id,
          'user_id2': this.user.user_id2,
          'group_id' : this.group.group_id,
          'note1' : this.note_group,
          'status' : this.statusGroup
      }, { 
          headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
      }).then(res => {
          this.loader.hide();
            $('#example_group').DataTable().destroy();
          this.getUserGroup();
          swal("Berhasil tambah User Group", {icon: "success", button: false})
          this.clearGroup()
      }).catch(err => {
          console.log(err.response.data.message);
          swal(err.response.data.message, {icon: "error"})
          this.loader.hide();
      })
    },
    updateUserGroupSave(){
        $("#editUserGroupModal").modal('hide');
        this.loaderLoad();

        axios.put(`${process.env.VUE_APP_URL}/api/group/update`, {
            'entity_id': this.editEntity.entity_id,
            'appl_id': this.editApplication.appl_id,
            'user_id2': this.editUser.user_id2,
            'group_id' : this.editGroup.group_id,
            'note1' : this.editNote_group,
            'status' : this.editStatusGroup
        }, { 
            headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
        }).then(res => {
            this.loader.hide();
            $('#example_group').DataTable().destroy();
            this.getUserGroup();
            swal("Berhasil update User Group", {icon: "success", button: false})
        }).catch(err => {
            console.log(err.response.data.message);
            swal(err.response.data.message, {icon: "error"})
            this.loader.hide();
        })
    }
  },
  created() {
    this.getUser()
    this.getUserGroup()
  }
}

</script>
<style>
.modal-dialog-scrollable .modal-content {
    max-height: 61%;
    overflow: hidden;
}
.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 144%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1pxsolidrgba(0,0,0,.2);
    border-radius: 0.3rem;
    outline: 0;
}
</style>
