<template>
  <div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-10">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><router-link :to="{path:'/admin/'}">Dashboard</router-link></li>
                    <li class="breadcrumb-item"><a href="#">Master</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Group Maintenance</li>
                </ol>
            </nav>
        </div>
        <div class="col-lg-2">
            <button class="btn btn-success btn-sm" style="float: right;" @click="showModalCreate()">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-plus-circle"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="16"></line><line x1="8" y1="12" x2="16" y2="12"></line></svg>
            </button>            
        </div>
        <div class="col-md-12">
            <div class="row">
                <div class="col-md-3 mt-2">
                    <label class="control-label">Entity</label>
                    <multiselect 
                        v-model="entityFilter" 
                        :custom-label="serviceEntityFilter" 
                        :options="entities" 
                        placeholder="Select One" 
                        label="description" 
                        track-by="description" 
                        name="entity_id" 
                        autofocuc="yes"
                        style="margin-top: 23px;"
                        @input="setEntityFilter()"
                    ></multiselect>
                </div>
                <div class="col-md-3 mt-2">
                    <label class="control-label">Application</label>
                    <multiselect 
                        v-model="applicationFilter" 
                        :custom-label="serviceApplication" 
                        :options="applications" 
                        placeholder="Select One" 
                        label="appl_id" 
                        track-by="appl_id" 
                        name="appl_id" 
                        autofocuc="yes"
                        style="margin-top: 23px;"
                        @input="setApplicationFilter()"
                    ></multiselect>
                </div>
                <div class="col-md-3 mt-2">
                    <label class="control-label">Group</label>
                    <multiselect 
                        v-model="groupFilter" 
                        :custom-label="serviceGroupMaster" 
                        :options="groupmasterss" 
                        placeholder="Select One" 
                        label="group_id" 
                        track-by="group_id"  
                        name="group_id" 
                        autofocuc="yes"
                        style="margin-top: 23px;"
                    ></multiselect>
                </div>
                <div class="col-md-3 mt-2">
                    <label class="control-label">Menu Header</label>
                    <multiselect 
                        v-model="menu_headerFilter" 
                        :custom-label="serviceMenuHeader" 
                        :options="menu_headers" 
                        placeholder="Select One" 
                        label="menu_header_id" 
                        track-by="menu_header_id" 
                        name="menu_header_id" 
                        autofocuc="yes"
                        style="margin-top: 23px;"
                        @input="setMenuFilter()"
                    ></multiselect>
                </div>
                <div class="col-md-3 mt-2">
                    <label class="control-label">Menu Item</label>
                    <multiselect 
                        v-model="menu_itemFilter" 
                        :custom-label="serviceMenuItem" 
                        :options="menu_items" 
                        placeholder="Select One" 
                        label="menu_id" 
                        track-by="menu_id" 
                        name="menu_id" 
                        autofocuc="yes"
                        style="margin-top: 23px;"
                    ></multiselect>
                </div>
                <div class="col-md-3 mt-2">
                    <label class="control-label">Status</label>
                    <multiselect
                        v-model="statusFilter" 
                        :custom-label="serviceStatus" 
                        :options="statuses" 
                        placeholder="Select One" 
                        label="value" 
                        track-by="value" 
                        name="value" 
                        autofocuc="yes"
                        style="margin-top: 23px;"
                    ></multiselect>
                </div>
                 <!-- :allow-empty="false" 
                 :showLabels="false"  -->
               <div class="col-5 mt-2">
                     <button class="btn btn-success btn-fill" style="margin-top: 24px; margin-right: 616px;" @click="clear()">
                        Clear
                    </button>
                    <button class="btn btn-info btn-fill" style="margin-top: -70px; margin-right: 360px;" @click="filter()">
                        Filter
                    </button>
                </div>
            </div>
        </div>
        <div class="col-md-12 mt-2">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-lg-12">
                            <table class="table table-striped table-sm table-bordered table-hover display nowrap" id="example" style="width:100%">
                                <thead>
                                    <tr>
                                        <th style="text-align:center !important">Entity</th>
                                        <th style="text-align:center !important">Application</th>
                                        <th style="text-align:center !important">Group</th>
                                        <th style="text-align:center !important">Menu Header</th>
                                        <th style="text-align:center !important">Menu Seq</th>
                                        <th style="text-align:center !important">Menu ID</th>
                                        <th style="text-align:center !important">Menu Item</th>
                                        <th style="text-align:center !important">Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="sec_right in sec_rights" :key="sec_right.id">
                                        <td>{{sec_right.entity_id}}</td>
                                        <td>{{sec_right.appl_id}}</td>
                                        <td>{{sec_right.group_id}}</td>
                                        <td>{{sec_right.menu_header}}</td>
                                        <td>{{sec_right.menu_seq}}</td>
                                        <td>{{sec_right.menu_id}}</td>
                                        <td>{{sec_right.menu_item}}</td>
                                        <td>
                                            <label class="switch" v-if="sec_right.active_flag === 'N'">
                                                <input type="checkbox" v-on:change="deleteSecRight(sec_right)">
                                                <span class="slider round"></span>
                                            </label>
                                            <label class="switch" v-else>
                                                <input type="checkbox" v-on:change="deleteSecRight(sec_right)" checked>
                                                <span class="slider round"></span>
                                            </label>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="createSecRightModal" tabindex="-1" aria-labelledby="createSecRightModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="createSecRightModalLabel">Tambah Group Maintenance</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body py-lg-10 px-lg-10">
                    <div class="row g-3 align-items-center">
                        <div class="col-md-3">
                            <label for="entity" class="col-form-label">Entity</label>
                        </div>
                        <div class="col-auto">
                            <multiselect 
                                v-model="entity" 
                                :custom-label="serviceEntity" 
                                :options="entities" 
                                placeholder="Select One" 
                                label="description" 
                                track-by="description" 
                                :allow-empty="false" 
                                :showLabels="false" 
                                name="entity_id" 
                                autofocuc="yes"
                                class="form-control"
                                @input="setEntity()"
                            ></multiselect>
                        </div>
                    </div>
                    <div class="row g-3 align-items-center mt-1">
                        <div class="col-md-3">
                            <label for="entity" class="col-form-label">Application</label>
                        </div>
                        <div class="col-auto">
                            <multiselect 
                                v-model="application" 
                                :custom-label="serviceApplication" 
                                :options="applications" 
                                placeholder="Select One" 
                                label="appl_id" 
                                track-by="appl_id" 
                                :allow-empty="false" 
                                :showLabels="false" 
                                name="appl_id" 
                                autofocuc="yes"
                                class="form-control"
                                @input="setApplication()"
                            ></multiselect>
                        </div>
                    </div>
                    <div class="row g-3 align-items-center mt-1">
                        <div class="col-md-3">
                            <label for="entity" class="col-form-label">Group</label>
                        </div>
                        <div class="col-auto">
                            <multiselect 
                                v-model="group" 
                                :custom-label="serviceGroupMaster" 
                                :options="groupmasterss" 
                                placeholder="Select One" 
                                label="group_id" 
                                track-by="group_id" 
                                :allow-empty="false" 
                                :showLabels="false" 
                                name="group_id" 
                                autofocuc="yes"
                                class="form-control"
                            ></multiselect>
                        </div>
                    </div>
                    <div class="row g-3 align-items-center mt-1">
                        <div class="col-md-3">
                            <label for="entity" class="col-form-label">Menu Header</label>
                        </div>
                        <div class="col-4">
                            <multiselect 
                                v-model="menu_header" 
                                :custom-label="serviceMenuHeader" 
                                :options="menu_headers" 
                                placeholder="Select One" 
                                label="menu_header_id" 
                                track-by="menu_header_id" 
                                :allow-empty="false" 
                                :showLabels="false" 
                                name="menu_header_id" 
                                autofocuc="yes"
                                class="form-control"
                                @input="setMenu()"
                            ></multiselect>
                        </div>
                    </div>
                    <div class="row g-3 align-items-center mt-1">
                        <div class="col-md-3">
                            <label for="entity" class="col-form-label">Menu Item</label>
                        </div>
                        <div class="col-auto">
                            <multiselect
                                v-model="menu_item" 
                                :custom-label="serviceMenuItem" 
                                :options="menu_items" 
                                placeholder="Select One" 
                                label="menu_id" 
                                track-by="menu_id" 
                                :allow-empty="false" 
                                :showLabels="false" 
                                name="menu_id" 
                                autofocuc="yes"
                                class="form-control"
                            ></multiselect>
                        </div>
                    </div>
                    <div class="row g-3 align-items-center mt-1">
                        <div class="col-md-3">
                            <label for="status" class="col-form-label">Status</label>
                        </div>
                        <div class="col-auto">
                            <select class="form-select" aria-label="Select" v-model="status">
                                <option value="Y">Aktif</option>
                                <option value="N">Tidak Aktif</option>
                            </select>
                        </div>
                    </div>                    
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="button" class="btn btn-success" @click="addSecRight()">Save</button>
                </div>
                </div>
            </div>
        <snackbar ref="snackbar" baseSize="100px" :holdTime="5000" position="top-center"></snackbar>
        </div>
      </div>
    </div>
  </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<script>
//Bootstrap and jQuery libraries
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery'; 
import Snackbar from "vuejs-snackbar";
import swal from 'sweetalert';
import Multiselect from 'vue-multiselect'

export default {
    mounted() {
        this.getEntity()
        this.getApplication()
        this.getMenuHeader()
        this.getMenuItem()
        this.getGroupMaster()
    },
    data(){
        return {
            sec_rights: [],
            fullPage: true, 
            loader : null,
            entity: '',
            description: '',
            status: null,
            editEntity: '',
            editDescription: '',
            editStatus: '',
            dataEntity: null,
            entities: [],
            entity: '',
            applications: [],
            application: '',
            menu_headers: [],
            options: ['list', 'of', 'options'],
            menu_header: '',
            menu_items: [],
            menu_item: '',
            groupmasterss: [],
            group: '',
            entityFilter: '',
            applicationFilter: '',
            menu_headerFilter: '',
            menu_itemFilter: '',
            selected: '',
            groupFilter: '',
            statusFilter: '',
            statuses: [
                {
                    desc : 'YA',
                    value : 'Y'
                },
                {
                    desc : 'NO',
                    value : 'N'
                }
            ]
        }
    },
    components: {
		Snackbar, Multiselect
	},
    methods:{
        clear(){
            this.entity = ''
            this.description = ''
            this.status = null
            this.application = ''
            this.menu_header = ''
            this.menu_item = ''
            this.group = ''
        },
        loaderLoad(){
            this.loader = this.$loading.show({
                // Optional parameters
                container: this.fullPage ? null : this.$refs.formContainer,
                color: '#0a58ca',
                loader: 'dots',
                width: 64,
                height: 64,
                backgroundColor: '#222223',
                opacity: 0.5,
                zIndex: 999,
            });
        },
        serviceStatus({desc}){
            return desc;
        },
        serviceEntityFilter({entity_id}){
            return entity_id;
        },
        getSecRight() {    
            //this.loaderLoad();

            axios.get(`${process.env.VUE_APP_URL}/api/sec_right`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
               // this.loader.hide();
                $('#example').DataTable().destroy();

                this.sec_rights = res.data
                this.$nextTick(() => {
                    $("#example").DataTable({
                        "responsive": true,
                        "autoWidth": false,
                        "ordering": false,
                        "info": true,
                        "pageLength": 10,
                        "bDestroy": true,
                        "recordsFiltered":10,
                        "destroy": true
                    });
                });
            });
        },
        showModalCreate(){
            $("#createSecRightModal").modal('show');
        },
        addSecRight(){
            
            $("#createSecRightModal").modal('hide');
            //this.loaderLoad();

            if (this.entity.entity_id == undefined && this.application.appl_id  == undefined && this.menu_header.menu_header_id  == undefined && this.menu_item.menu_id  == undefined && this.group.group_id  == undefined) {
                this.loader.hide();
                return this.$refs.snackbar.error("Mohon dilengkapi datanya");
            } else if(this.entity.entity_id == undefined){
                this.loader.hide();
                return this.$refs.snackbar.error("Mohon diisi entity anda");
            } else if(this.application.appl_id  == undefined){
                this.loader.hide();
                return this.$refs.snackbar.error("Mohon diisi aplikasi anda");
            } else if(this.menu_header.menu_header_id  == undefined){
                this.loader.hide();
                return this.$refs.snackbar.error("Mohon diisi menu header");
            } else if(this.menu_item.menu_id  == undefined){
                this.loader.hide();
                return this.$refs.snackbar.error("Mohon diisi menu");
            } else if(this.group.group_id  == undefined){
                this.loader.hide();
                return this.$refs.snackbar.error("Mohon diisi group");
            } 

            axios.post(`${process.env.VUE_APP_URL}/api/sec_right`, {
                'entity_id': this.entity.entity_id,
                'appl_id': this.application.appl_id,
                'group_id': this.group.group_id,
                'menu_header_id': this.menu_header.menu_header_id,
                'parent_id': this.menu_header.menu_caption,
                'menu_id': this.menu_item.menu_id,
                'seq_id' : this.menu_item.seq_id,
                'status' : this.status
            }, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            }).then(res => {
                 $("#createSecRightModal").modal('hide');
                //this.loader.hide();
                $('#example').DataTable().destroy();
               this.getSecRight();
                swal("Berhasil tambah group right", {icon: "success", button: false})
                this.clear()
            }).catch(err => {
                //this.loader.hide();
                swal(err.response.data.message, {icon: "error", button: false})
            })
        },
        EditModal(data){
            this.dataEntity = data
            this.editEntity = data.entity_id
            this.editDescription = data.description
            this.editStatus = data.status
            $("#EditEntityModal").modal('show');
        },
        updateEntity(){
            $("#EditEntityModal").modal('hide');
            this.loaderLoad();

            axios.put(`${process.env.VUE_APP_URL}/api/master/entity/update`, {
                'entity': this.editEntity,
                'description': this.editDescription,
                'status': this.editStatus
            }, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            }).then(res => {
                this.loader.hide();
                this.getSecRight();
                this.$refs.snackbar.success("Berhasil edit entity");
            }).catch(err => {
                this.loader.hide();
                swal(err.response.data.message, {icon: "error", button: false})
            })
        },
        deleteSecRight(sec_right){
            if (sec_right.active_flag === 'Y') {
                this.updateStatus(sec_right, 'N')
            } else{
                this.updateStatus(sec_right, 'Y')
            }
        },
        updateStatus(sec_right, status){
            axios.put(`${process.env.VUE_APP_URL}/api/sec_right/updateSecRightStatus`, {
                'entity_id': sec_right.entity_id,
                'appl_id' : sec_right.appl_id,
                'menu_header_id' : sec_right.menu_header_id,
                'menu_id' : sec_right.menu_id,
                'group_id' : sec_right.group_id,
                'menu_seq' : sec_right.menu_seq,
                'status': status
            }, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            }).then(res => {
                $('#example').DataTable().destroy();
                this.getSecRight();
                swal("Berhasil update status", {icon: "success", button: false})
            }).catch(err => {
                swal(err.response.data.message, {icon: "error", button: false})
            })
        },
        serviceEntity (option) {
            return `${option.entity_id} - ${option.description}`
        },
        setEntityFilter(){
            axios.get(`${process.env.VUE_APP_URL}/api/master/aplikasi?entity_id=${this.entityFilter.entity_id}&status=Y`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.applications = res.data
            });
        },
        setEntity(){
            axios.get(`${process.env.VUE_APP_URL}/api/master/aplikasi?entity_id=${this.entity.entity_id}&status=Y`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.applications = res.data
            });
        },
        getEntity() {    
            axios.get(`${process.env.VUE_APP_URL}/api/master/entity?status=1`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.entities = res.data
            });
        },
        serviceApplication({appl_id}){
			return appl_id
		},
        setApplication(){
            this.setGroup()
            axios.get(`${process.env.VUE_APP_URL}/api/master/menu_header?entity_id=${this.entity.entity_id}&appl_id=${this.application.appl_id}&active_flag=Y`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.menu_headers = res.data
            });
        },
        setApplicationFilter(){
           this.setGroupFilter()
            axios.get(`${process.env.VUE_APP_URL}/api/master/menu_header?entity_id=${this.entityFilter.entity_id}&appl_id=${this.applicationFilter.appl_id}&active_flag=Y`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.menu_headers = res.data
            });
        },
        getApplication() {    
            axios.get(`${process.env.VUE_APP_URL}/api/master/aplikasi?status=Y`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.applications = res.data
            });
        },
        serviceMenuHeader({menu_caption}){
			return menu_caption
		},
        getMenuHeader() {    
            axios.get(`${process.env.VUE_APP_URL}/api/master/menu_header?active_flag=Y`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.menu_headers = res.data
            });
        },
        setMenu(){
            axios.get(`${process.env.VUE_APP_URL}/api/menu?entity_id=${this.entity.entity_id}&appl_id=${this.application.appl_id}&menu_header_id=${this.menu_header.menu_header_id}&active_flag=Y`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.menu_items = res.data
            });
        },
        setMenuFilter(){
            axios.get(`${process.env.VUE_APP_URL}/api/menu?entity_id=${this.entityFilter.entity_id}&appl_id=${this.applicationFilter.appl_id}&menu_header_id=${this.menu_headerFilter.menu_header_id}&active_flag=Y`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.menu_items = res.data
            });
        },
        serviceMenuItem({menu_id, menu_caption}){
			return `${menu_id} - ${menu_caption}`
		},
        getMenuItem() {    
            axios.get(`${process.env.VUE_APP_URL}/api/menu?active_flag=Y`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.menu_items = res.data
            });
        },
        serviceGroupMaster({group_id}){
            return group_id
        },
        getGroupMaster() {    
            axios.get(`${process.env.VUE_APP_URL}/api/group/select?active_flag=Y`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.groupmasterss = res.data
            });
        },
        setGroup(){
            axios.get(`${process.env.VUE_APP_URL}/api/group/select?entity_id=${this.entity.entity_id}&appl_id=${this.application.appl_id}&active_flag=Y`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.groupmasterss = res.data
            });
        },
        setGroupFilter(){
            axios.get(`${process.env.VUE_APP_URL}/api/group/select?entity_id=${this.entityFilter.entity_id}&appl_id=${this.applicationFilter.appl_id}&active_flag=Y`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.groupmasterss = res.data
            });
        },
        clear(){
            //this.loaderLoad();
            this.entityFilter = ''
            this.statusFilter = ''
            this.applicationFilter = ''
            this.menu_headerFilter = ''
            this.menu_itemFilter = ''
            this.groupFilter = ''
            //this.getSecRight()
           
        },
        filter(){
           //this.loaderLoad();
        //    console.log(this.menu_itemFilter.menu_id)
            var url = ''

            if(this.entityFilter.entity_id == 'undefined'){
                this.entityFilter.entity_id = ''
            }

            if(this.applicationFilter.appl_id == 'undefined'){
                this.applicationFilter.appl_id = ''
            }

            if(this.menu_headerFilter.menu_header_id == 'undefined'){
                this.menu_headerFilter.menu_header_id = ''
            }

            if(this.menu_itemFilter.menu_id == 'undefined'){
                this.menu_itemFilter.menu_id = ''
            }

            if(this.groupFilter.group_id == 'undefined'){
                this.groupFilter.group_id = ''
            }

            if(this.statusFilter.value == 'undefined'){
                this.statusFilter.value = ''
            }

            url = `${process.env.VUE_APP_URL}/api/sec_right?entity_id=${this.entityFilter.entity_id ? this.entityFilter.entity_id : ''}&appl_id=${this.applicationFilter.appl_id ? this.applicationFilter.appl_id : ''}&menu_header_id=${this.menu_headerFilter.menu_header_id ? this.menu_headerFilter.menu_header_id : ''}&group_id=${this.groupFilter.group_id ? this.groupFilter.group_id : ''}&active_flag=${this.statusFilter.value ? this.statusFilter.value : ''}`

            // if (this.entityFilter.entity_id === undefined && this.applicationFilter.appl_id === undefined && this.menu_headerFilter.menu_header_id === undefined && this.menu_itemFilter.menu_id === undefined && this.groupFilter.group_id === undefined && this.statusFilter.value === undefined) {
               
            // //return this.getSecRight()
            // } else if(this.entityFilter.entity_id != undefined && this.applicationFilter.appl_id != undefined && this.menu_headerFilter.menu_header_id != undefined && this.menu_itemFilter.menu_id != undefined && this.groupFilter.group_id != undefined && this.statusFilter.value != undefined){

            //     url = `${process.env.VUE_APP_URL}/api/sec_right?entity_id=${this.entityFilter.entity_id ? this.entityFilter.entity_id : ''}&appl_id=${this.applicationFilter.appl_id}&group_id=${this.groupFilter.group_id}&menu_header_id=${this.menu_headerFilter.menu_header_id}&menu_id=${this.menu_itemFilter.menu_id}&active_flag=${this.statusFilter.value}`

            // } else if(this.entityFilter.entity_id != undefined && this.applicationFilter.appl_id == undefined && this.menu_headerFilter.menu_header_id == undefined && this.menu_itemFilter.menu_id == undefined && this.groupFilter.group_id == undefined && this.statusFilter.value == undefined){

            //     url = `${process.env.VUE_APP_URL}/api/sec_right?entity_id=${this.entityFilter.entity_id}`

            // } else if(this.entityFilter.entity_id != undefined && this.applicationFilter.appl_id != undefined && this.menu_headerFilter.menu_header_id == undefined && this.menu_itemFilter.menu_id == undefined && this.groupFilter.group_id == undefined && this.statusFilter.value == undefined){

            //     url = `${process.env.VUE_APP_URL}/api/sec_right?entity_id=${this.entityFilter.entity_id}&appl_id=${this.applicationFilter.appl_id}`

            // } else if(this.entityFilter.entity_id != undefined && this.applicationFilter.appl_id != undefined && this.menu_headerFilter.menu_header_id != undefined && this.menu_itemFilter.menu_id == undefined && this.groupFilter.group_id == undefined && this.statusFilter.value == undefined){

            //     url = `${process.env.VUE_APP_URL}/api/sec_right?entity_id=${this.entityFilter.entity_id}&appl_id=${this.applicationFilter.appl_id}&menu_header_id=${this.menu_headerFilter.menu_header_id}`

            // } else if(this.entityFilter.entity_id != undefined && this.applicationFilter.appl_id != undefined && this.menu_headerFilter.menu_header_id == undefined && this.menu_itemFilter.menu_id == undefined && this.groupFilter.group_id != undefined && this.statusFilter.value == undefined){

            //     url = `${process.env.VUE_APP_URL}/api/sec_right?entity_id=${this.entityFilter.entity_id}&appl_id=${this.applicationFilter.appl_id}&group_id=${this.groupFilter.group_id}`

            // } else if(this.entityFilter.entity_id != undefined && this.applicationFilter.appl_id != undefined && this.menu_headerFilter.menu_header_id != undefined && this.menu_itemFilter.menu_id == undefined && this.groupFilter.group_id != undefined && this.statusFilter.value == undefined){

            //     url = `${process.env.VUE_APP_URL}/api/sec_right?entity_id=${this.entityFilter.entity_id}&appl_id=${this.applicationFilter.appl_id}&menu_header_id=${this.menu_headerFilter.menu_header_id}&group_id=${this.groupFilter.group_id}`

            // } else {
            //     //this.loader.hide();
            //     return this.getSecRight()
            // }

            axios.get(url, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                //this.loader.hide();

                $('#example').DataTable().destroy();

                this.sec_rights = res.data
                this.$nextTick(() => {
                    $("#example").DataTable({
                        "responsive": true,
                        "autoWidth": false,
                        "ordering": false,
                        "info": true,
                        "pageLength": 10,
                        "bDestroy": true,
                        "recordsFiltered":10,
                        "destroy": true,
                    });
                });

                // this.entityFilter = ''
                // this.statusFilter = ''
                // this.applicationFilter = ''
                // this.menu_headerFilter = ''
                // this.menu_itemFilter = ''
                // this.groupFilter = ''
            })
            // console.log(url);
        },
    },
    created() {
        this.getSecRight()
    }
}
</script>
<style>
.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 137%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
    outline: 0;
}
.multiselect--above .multiselect__content-wrapper {
    bottom: 118%;
    width: 123%;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom: none;
    border-top: 1px solid #e8e8e8;
}
</style>