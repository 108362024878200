<template>
  <nav class="navbar navbar-expand-lg">
    <div class="container-fluid">
      <a class="navbar-brand" href="#">Dashboard</a>
      <button type="button"
              class="navbar-toggler navbar-toggler-right"
              :class="{toggled: $sidebar.showSidebar}"
              aria-controls="navigation-index"
              aria-expanded="false"
              aria-label="Toggle navigation"
              @click="toggleSidebar">
        <span class="navbar-toggler-bar burger-lines"></span>
        <span class="navbar-toggler-bar burger-lines"></span>
        <span class="navbar-toggler-bar burger-lines"></span>
      </button>
      <div class="collapse navbar-collapse justify-content-end">
        <ul class="nav navbar-nav mr-auto">
          <!-- <li class="nav-item">
            <a class="nav-link" href="#" data-toggle="dropdown">
              <i class="nc-icon nc-palette"></i>
            </a>
          </li> -->
          <!-- <base-dropdown tag="li">
            <template slot="title">
              <i class="nc-icon nc-planet"></i>
              <b class="caret"></b>
              <span class="notification">5</span>
            </template>
            <a class="dropdown-item" href="#">Notification 1</a>
            <a class="dropdown-item" href="#">Notification 2</a>
            <a class="dropdown-item" href="#">Notification 3</a>
            <a class="dropdown-item" href="#">Notification 4</a>
            <a class="dropdown-item" href="#">Another notification</a>
          </base-dropdown> -->
          <!-- <li class="nav-item">
            <a href="#" class="nav-link">
              <i class="nc-icon nc-zoom-split"></i>
              <span class="d-lg-block">&nbsp;Search</span>
            </a>
          </li> -->
        </ul>
        <ul class="navbar-nav ml-auto">
          <!-- <base-dropdown title="Master">
            <router-link class="dropdown-item" :to="{path:'/admin/master/entity'}">Entity</router-link>
            <router-link class="dropdown-item" :to="{path:'/admin/master/application'}">Application</router-link>
            <router-link class="dropdown-item" :to="{path:'/admin/master/group'}">Group Master</router-link>
            <router-link class="dropdown-item" :to="{path:'/admin/master/menuHeader'}">Menu Header</router-link>
            <router-link class="dropdown-item" :to="{path:'/admin/master/menuItem'}">Menu Item</router-link>
            <router-link class="dropdown-item" :to="{path:'/admin/master/env-variabel'}">Env Variabel</router-link>
            <router-link class="dropdown-item" :to="{path:'/admin/master/env-variabel-conf'}">Env Configuration</router-link>
            <div class="divider"></div>
            <router-link class="dropdown-item" :to="{path:'/admin/master/group-maintenance'}">Group Right </router-link>
          </base-dropdown> -->
          <li class="nav-item">
            <a class="nav-link" @click="logOut()">
              Log out
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
  export default {
    computed: {
      routeName () {
        const {name} = this.$route
        return this.capitalizeFirstLetter(name)
      }
    },
    data () {
      return {
        activeNotifications: false
      }
    },
    methods: {
      capitalizeFirstLetter (string) {
        return string.charAt(0).toUpperCase() + string.slice(1)
      },
      toggleNotificationDropDown () {
        this.activeNotifications = !this.activeNotifications
      },
      closeDropDown () {
        this.activeNotifications = false
      },
      toggleSidebar () {
        this.$sidebar.displaySidebar(!this.$sidebar.showSidebar)
      },
      hideSidebar () {
        this.$sidebar.displaySidebar(false)
      },
      logOut(){
        let loader = this.$loading.show({
            // Optional parameters
            container: this.fullPage ? null : this.$refs.formContainer,
            color: '#0a58ca',
            loader: 'dots',
            width: 64,
            height: 64,
            backgroundColor: '#222223',
            opacity: 0.5,
            zIndex: 999,
        });

        this.$store.dispatch("logoutUser").then(() => {
            loader.hide();
            this.$router.push('/');
        
				});
      }
    }
  }

</script>
<style>

</style>
